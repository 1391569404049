<template>
<form
  id="app"
>
  <b-card-code title="Formulario Desenvolvimentos">
    <b-row v-if="solidesenvolvimentoid > 0">
      <b-col
        md="6"
        xl="3"
        class="mb-1"
      >
        <b-form-group
          label="Nº Solicitação:"
          
        >
          <b-form-input
            id="numsoliproduto"
            placeholder=""
            v-model="numsoliproduto"
            readonly
          />
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        xl="3"
        class="mb-1"
      >
        <b-form-group
          label="Data Inicio do Desenvolvimento:"
          
        >
         <b-form-datepicker
            id="datainicdesenvolvimento" locale="pr-BR"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            v-model="datainicdesenvolvimento"
            placeholder="---------"
            disabled
          />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row v-if="solidesenvolvimentoid > 0">
      <b-col
        md="6"
        xl="12"
        class="mb-1"
      >
        <b-form-group
          label="Produto:"
          
        >
          <b-form-input
            id="produtoInput"
            placeholder=""
            v-model="produto"
            required
            readonly
          />
        </b-form-group>
      </b-col>
    </b-row>

</b-card-code>

<div id="DivPD1" v-if="NivelEtapa >= 1">
<b-card-code title="Planejamento do desenvolvimento">
  <b-row>
      <b-col
        md="6"
        xl="3"
        class="mb-1"
      >
        <b-form-group
          label="Data Planejado:"
          
        >
          <b-form-datepicker
            id="plandesdatap" locale="pr-BR"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            today-button
            reset-button
            v-model="plandesdatap"
            placeholder="Selecione"
            :disabled="PermiteSalvarPD1 ? false : '' "
          />
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        xl="3"
        class="mb-1"
      >
        <b-form-group
          label="Data Realizado:"
          
        >
          <b-form-datepicker
            id="plandesdatar" locale="pr-BR"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            today-button
            reset-button
            v-model="plandesdatar"
            placeholder="Selecione"
            :disabled="PermiteSalvarPD1 ? false : '' "
          />
        </b-form-group>
      </b-col>
  </b-row>
  <b-row>
    <b-col
        md="6"
        xl="12"
        class="mb-1"
      >
        <b-form-group
          label="Observação:"
          
        >
          <b-form-textarea
            id="plandesdesc"
            placeholder=""
            v-model="plandesdesc"
            rows="3"
            :readonly="PermiteSalvarPD1 ? false : '' "
          />
        </b-form-group>
      </b-col>
  </b-row>

</b-card-code>

<b-card-code title="Pesquisa de matérias primas e ingredientes/ solicitação de amostras">
  <b-row>
      <b-col
        md="6"
        xl="3"
        class="mb-1"
      >
        <b-form-group
          label="Data Planejado:"
          
        >
          <b-form-datepicker
            id="pesqmatpriingdatap" locale="pr-BR"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            today-button
            reset-button
            v-model="pesqmatpriingdatap"
            placeholder="Selecione"
            :disabled="PermiteSalvarPD1 ? false : '' "
          />
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        xl="3"
        class="mb-1"
      >
        <b-form-group
          label="Data Realizado:"
          
        >
          <b-form-datepicker
            id="pesqmatpriingdatar" locale="pr-BR"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            today-button
            reset-button
            v-model="pesqmatpriingdatar"
            placeholder="Selecione"
            :disabled="PermiteSalvarPD1 ? false : '' "
          />
        </b-form-group>
      </b-col>
  </b-row>
  <b-row>
    <b-col
        md="6"
        xl="12"
        class="mb-1"
      >
        <b-form-group
          label="Observação:"
          
        >
          <b-form-textarea
            id="pesqmatpriingdesc"
            placeholder=""
            v-model="pesqmatpriingdesc"
            rows="3"
            :readonly="PermiteSalvarPD1 ? false : '' "
          />
        </b-form-group>
      </b-col>
  </b-row>

  <b-row>
    <b-col
        md="6"
        xl="12"
        class="mb-1"
    >
    <b-row>
    <b-col
        md="6"
        xl="8"
        class="mb-1"
    >
     <b-form-file
      truncate-length="50"
      accept=".jpeg, .png, .pfd, .xls, .xlsx, .doc, .docx"
      placeholder="Selecione o arquivo..."
      @change="carregarPesqMatPriIng"
      v-model="PesqMatPriIng"
      ref="PesqMatPriIng-input"
      v-if="PermiteSalvarPD1"
     ></b-form-file>
    </b-col>
    <b-col
        md="6"
        xl="2"
        class="mb-1"
    >
     <b-button  variant="primary" v-if="PermiteSalvarPD1"
      type="button" @click="salvarDocumento(PesqMatPriIngDescr)"><span>ADICIONAR</span></b-button>

    </b-col>
    </b-row>

    <b-table
      responsive
      class="position-relative"
      :per-page="perPage"
      :current-page="currentPagePesqMatPriIng"
      :items="ListPesqMatPriIng"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="filter"
      :filter-included-fields="filterOn"
      table-class = "font-size11"
      thead-class = "font-size11"
      thead-tr-class = "font-size11"
    >
    <template #cell(id)="row">
      <div class="DivBotaoLadoLado" >
        <b-button size="sm" @click="DownloadDocumento(row.value, PesqMatPriIngDescr)" class="mr-1">
          BAIXAR
        </b-button>
      </div>
      <div class="DivBotaoLadoLado" >
        <b-button v-if="PermiteSalvarPD1"
         variant="danger"
         size="sm" @click="RemoverDocumento(row.value, PesqMatPriIngDescr)" class="mr-1">
          REMOVER
        </b-button>
      </div>
    </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <!-- page length -->
      <b-form-group
        label="Registros por Pagina"
        label-cols="8"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="currentPagePesqMatPriIng"
          :total-rows="totalRowsPesqMatPriIng"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card-body>
    </b-col>
  </b-row>
</b-card-code>

<b-card-code title="Testes de formulações">
  <b-row>
      <b-col
        md="6"
        xl="3"
        class="mb-1"
      >
        <b-form-group
          label="Data Planejado:"
          
        >
          <b-form-datepicker
            id="testformdatap" locale="pr-BR"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            today-button
            reset-button
            v-model="testformdatap"
            placeholder="Selecione"
            :disabled="PermiteSalvarPD1 ? false : '' "
          />
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        xl="3"
        class="mb-1"
      >
        <b-form-group
          label="Data Realizado:"
          
        >
          <b-form-datepicker
            id="testformdatar" locale="pr-BR"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            today-button
            reset-button
            v-model="testformdatar"
            placeholder="Selecione"
            :disabled="PermiteSalvarPD1 ? false : '' "
          />
        </b-form-group>
      </b-col>
  </b-row>
  <b-row>
    <b-col
        md="6"
        xl="12"
        class="mb-1"
      >
        <b-form-group
          label="Observação:"
          
        >
          <b-form-textarea
            id="testformdesc"
            placeholder=""
            v-model="testformdesc"
            rows="3"
            :readonly="PermiteSalvarPD1 ? false : '' "
          />
        </b-form-group>
      </b-col>
  </b-row>

  <b-row>
    <b-col
        md="6"
        xl="12"
        class="mb-1"
    >
    <b-row>
    <b-col
        md="6"
        xl="8"
        class="mb-1"
    >
     <b-form-file
      truncate-length="50"
      accept=".jpeg, .png, .pfd, .xls, .xlsx, .doc, .docx"
      placeholder="Selecione o arquivo..."
      @change="carregarTestForm"
      v-model="TestForm"
      ref="TestForm-input"
      v-if="PermiteSalvarPD1"
     ></b-form-file>
    </b-col>
    <b-col
        md="6"
        xl="2"
        class="mb-1"
    >
     <b-button  variant="primary"
      v-if="PermiteSalvarPD1"
      type="button" @click="salvarDocumento(TestFormDescr)"><span>ADICIONAR</span></b-button>

    </b-col>
    </b-row>
    <b-table
      responsive
      class="position-relative"
      :per-page="perPage"
      :current-page="currentPageTestForm"
      :items="ListTestForm"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="filter"
      :filter-included-fields="filterOn"
      table-class = "font-size11"
      thead-class = "font-size11"
      thead-tr-class = "font-size11"
    >
    <template #cell(id)="row">
      <div class="DivBotaoLadoLado" >
        <b-button size="sm" @click="DownloadDocumento(row.value, TestFormDescr)" class="mr-1">
          BAIXAR
        </b-button>
      </div>
      <div class="DivBotaoLadoLado" >
        <b-button
         variant="danger" v-if="PermiteSalvarPD1"
         size="sm" @click="RemoverDocumento(row.value, TestFormDescr)" class="mr-1">
          REMOVER
        </b-button>
      </div>
    </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <!-- page length -->
      <b-form-group
        label="Registros por Pagina"
        label-cols="8"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="currentPageTestForm"
          :total-rows="totalRowsTestForm"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card-body>
    </b-col>
  </b-row>
</b-card-code>

<b-card-code title="Envio de amostra">
  <b-row>
      <b-col
        md="6"
        xl="3"
        class="mb-1"
      >
        <b-form-group
          label="Data Planejado:"
          
        >
          <b-form-datepicker
            id="envioamostradatap" locale="pr-BR"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            today-button
            reset-button
            v-model="envioamostradatap"
            placeholder="Selecione"
            :disabled="PermiteSalvarPD1 ? false : '' "
          />
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        xl="3"
        class="mb-1"
      >
        <b-form-group
          label="Data Realizado:"
          
        >
          <b-form-datepicker
            id="envioamostradatar" locale="pr-BR"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            today-button
            reset-button
            v-model="envioamostradatar"
            placeholder="Selecione"
            :disabled="PermiteSalvarPD1 ? false : '' "
          />
        </b-form-group>
      </b-col>
  </b-row>
  <b-row>
    <b-col
        md="6"
        xl="12"
        class="mb-1"
      >
        <b-form-group
          label="Observação:"
          
        >
          <b-form-textarea
            id="envioamostradesc"
            placeholder=""
            v-model="envioamostradesc"
            rows="3"
            :readonly="PermiteSalvarPD1 ? false : '' "
          />
        </b-form-group>
      </b-col>
  </b-row>

  <b-row>
    <b-col
        md="6"
        xl="12"
        class="mb-1"
    >
    <b-row>
    <b-col
        md="6"
        xl="8"
        class="mb-1"
    >
     <b-form-file
      truncate-length="50"
      accept=".jpeg, .png, .pfd, .xls, .xlsx, .doc, .docx"
      placeholder="Selecione o arquivo..."
      @change="carregarEnvioAmostra"
      v-model="EnvioAmostra"
      ref="EnvioAmostra-input"
      v-if="PermiteSalvarPD1"
     ></b-form-file>
    </b-col>
    <b-col
        md="6"
        xl="2"
        class="mb-1"
    >
     <b-button  variant="primary"
      v-if="PermiteSalvarPD1"
      type="button" @click="salvarDocumento(EnvioAmostraDescr)"><span>ADICIONAR</span></b-button>

    </b-col>
    </b-row>
    <b-table
      responsive
      class="position-relative"
      :per-page="perPage"
      :current-page="currentPageEnvioAmostra"
      :items="ListEnvioAmostra"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="filter"
      :filter-included-fields="filterOn"
      table-class = "font-size11"
      thead-class = "font-size11"
      thead-tr-class = "font-size11"
    >
    <template #cell(id)="row">
      <div class="DivBotaoLadoLado" >
        <b-button size="sm" @click="DownloadDocumento(row.value, EnvioAmostraDescr)" class="mr-1">
          BAIXAR
        </b-button>
      </div>
      <div class="DivBotaoLadoLado" >
        <b-button
         variant="danger" v-if="PermiteSalvarPD1"
         size="sm" @click="RemoverDocumento(row.value, EnvioAmostraDescr)" class="mr-1">
          REMOVER
        </b-button>
      </div>
    </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <!-- page length -->
      <b-form-group
        label="Registros por Pagina"
        label-cols="8"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="currentPageEnvioAmostra"
          :total-rows="totalRowsEnvioAmostra"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card-body>
    </b-col>
  </b-row>
</b-card-code>

<b-card-code title="Aprovação da amostra pelo cliente">
  <b-row>
      <b-col
        md="6"
        xl="6"
        class="mb-1"
      >
        <b-form-group
          label=""
        >
          <b-form-radio-group>
            <input name="aprovamostclient" type="radio" id="Sim" value="Sim"
                :disabled="PermiteSalvarPD1 ? false : '' "
                 v-model="aprovamostclient" required> Sim
            <input name="aprovamostclient" type="radio" id="Não" value="Não"
                :disabled="PermiteSalvarPD1 ? false : '' "
                 v-model="aprovamostclient" required> Não
          </b-form-radio-group>
        </b-form-group>
      </b-col>
 </b-row>
  <b-row>
    <b-col
        md="6"
        xl="12"
        class="mb-1"
      >
        <b-form-group
          label="Observação:"
        >
          <b-form-textarea
            id="aprovamostclientdesc"
            placeholder=""
            v-model="aprovamostclientdesc"
            rows="3"
            :readonly="PermiteSalvarPD1 ? false : '' "
          />
        </b-form-group>
      </b-col>
  </b-row>

  <b-row>
    <b-col
        md="6"
        xl="12"
        class="mb-1"
    >
    <b-row>
    <b-col
        md="6"
        xl="8"
        class="mb-1"
    >
     <b-form-file
      truncate-length="50"
      accept=".jpeg, .png, .pfd, .xls, .xlsx, .doc, .docx"
      placeholder="Selecione o arquivo..."
      @change="carregarAprovAmostClient"
      v-model="AprovAmostClient"
      ref="AprovAmostClient-input"
      v-if="PermiteSalvarPD1"
     ></b-form-file>
    </b-col>
    <b-col
        md="6"
        xl="2"
        class="mb-1"
    >
     <b-button  variant="primary"
      v-if="PermiteSalvarPD1"
      type="button" @click="salvarDocumento(AprovAmostClientDescr)"><span>ADICIONAR</span></b-button>

    </b-col>
    </b-row>
    <b-table
      responsive
      class="position-relative"
      :per-page="perPage"
      :current-page="currentPageAprovAmostClient"
      :items="ListAprovAmostClient"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="filter"
      :filter-included-fields="filterOn"
      table-class = "font-size11"
      thead-class = "font-size11"
      thead-tr-class = "font-size11"
    >
    <template #cell(id)="row">
      <div class="DivBotaoLadoLado" >
        <b-button size="sm" @click="DownloadDocumento(row.value, AprovAmostClientDescr)" class="mr-1">
          BAIXAR
        </b-button>
      </div>
      <div class="DivBotaoLadoLado" >
        <b-button
         variant="danger" v-if="PermiteSalvarPD1"
         size="sm" @click="RemoverDocumento(row.value, AprovAmostClientDescr)" class="mr-1">
          REMOVER
        </b-button>
      </div>
    </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <!-- page length -->
      <b-form-group
        label="Registros por Pagina"
        label-cols="8"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="currentPageAprovAmostClient"
          :total-rows="totalRowsAprovAmostClient"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card-body>
    </b-col>
  </b-row>
</b-card-code>

<b-card-code title="Envio de informações para estimativa de custo">
  <b-row>
      <b-col
        md="6"
        xl="3"
        class="mb-1"
      >
        <b-form-group
          label="Data Planejado:"
        
        >
          <b-form-datepicker
            id="envioinfestimcustodatap" locale="pr-BR"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            today-button
            reset-button
            v-model="envioinfestimcustodatap"
            placeholder="Selecione"
            :disabled="PermiteSalvarPD1 ? false : '' "
          />
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        xl="3"
        class="mb-1"
      >
        <b-form-group
          label="Data Realizado:"
          
        >
          <b-form-datepicker
            id="envioinfestimcustodatar" locale="pr-BR"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            today-button
            reset-button
            v-model="envioinfestimcustodatar"
            placeholder="Selecione"
            :disabled="PermiteSalvarPD1 ? false : '' "
          />
        </b-form-group>
      </b-col>
  </b-row>
  <b-row>
    <b-col
        md="6"
        xl="12"
        class="mb-1"
      >
        <b-form-group
          label="Observação:"
          
        >
          <b-form-textarea
            id="envioinfestimcustodesc"
            placeholder=""
            v-model="envioinfestimcustodesc"
            rows="3"
            :readonly="PermiteSalvarPD1 ? false : '' "
          />
        </b-form-group>
      </b-col>
  </b-row>

  <b-row>
    <b-col
        md="6"
        xl="12"
        class="mb-1"
    >
    <b-row>
    <b-col
        md="6"
        xl="8"
        class="mb-1"
    >
     <b-form-file
      truncate-length="50"
      accept=".jpeg, .png, .pfd, .xls, .xlsx, .doc, .docx"
      placeholder="Selecione o arquivo..."
      @change="carregarEnvioInfEstimCusto"
      v-model="EnvioInfEstimCusto"
      ref="EnvioInfEstimCusto-input"
      v-if="PermiteSalvarPD1"
     ></b-form-file>
    </b-col>
    <b-col
        md="6"
        xl="2"
        class="mb-1"
    >
     <b-button  variant="primary"
      v-if="PermiteSalvarPD1"
      type="button" @click="salvarDocumento(EnvioInfEstimCustoDescr)"><span>ADICIONAR</span></b-button>

    </b-col>
    </b-row>
    <b-table
      responsive
      class="position-relative"
      :per-page="perPage"
      :current-page="currentPageEnvioInfEstimCusto"
      :items="ListEnvioInfEstimCusto"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="filter"
      :filter-included-fields="filterOn"
      table-class = "font-size11"
      thead-class = "font-size11"
      thead-tr-class = "font-size11"
    >
    <template #cell(id)="row">
      <div class="DivBotaoLadoLado" >
        <b-button size="sm" @click="DownloadDocumento(row.value, EnvioInfEstimCustoDescr)" class="mr-1">
          BAIXAR
        </b-button>
      </div>
      <div class="DivBotaoLadoLado" >
        <b-button
         variant="danger" v-if="PermiteSalvarPD1"
         size="sm" @click="RemoverDocumento(row.value, EnvioInfEstimCustoDescr)" class="mr-1">
          REMOVER
        </b-button>
      </div>
    </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <!-- page length -->
      <b-form-group
        label="Registros por Pagina"
        label-cols="8"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="currentPageEnvioInfEstimCusto"
          :total-rows="totalRowsEnvioInfEstimCusto"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card-body>
    </b-col>
  </b-row>
</b-card-code>

<b-card-code title="Estimativa de custo (R$/kg)">
  <b-row>
      <b-col
        md="6"
        xl="3"
        class="mb-1"
      >
        <b-form-group
          label="Data Planejado:"
          
        >
          <b-form-datepicker
            id="estimcustorskgdatap" locale="pr-BR"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            today-button
            reset-button
            v-model="estimcustorskgdatap"
            placeholder="Selecione"
            :disabled="PermiteSalvarPD1 ? false : '' "
          />
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        xl="3"
        class="mb-1"
      >
        <b-form-group
          label="Data Realizado:"
          
        >
          <b-form-datepicker
            id="estimcustorskgdatar" locale="pr-BR"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            today-button
            reset-button
            v-model="estimcustorskgdatar"
            placeholder="Selecione"
            :disabled="PermiteSalvarPD1 ? false : '' "
          />
        </b-form-group>
      </b-col>
  </b-row>
  <b-row>
    <b-col
        md="6"
        xl="12"
        class="mb-1"
      >
        <b-form-group
          label="Observação:"
          
        >
          <b-form-textarea
            id="estimcustorskgdesc"
            placeholder=""
            v-model="estimcustorskgdesc"
            rows="3"
            :readonly="PermiteSalvarPD1 ? false : '' "
          />
        </b-form-group>
      </b-col>
  </b-row>
</b-card-code>

<b-card-code title="Planejamento do processo/embalagens">
  <b-row>
      <b-col
        md="6"
        xl="3"
        class="mb-1"
      >
        <b-form-group
          label="Data Planejado:"
          
        >
          <b-form-datepicker
            id="planprocembdatap" locale="pr-BR"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            today-button
            reset-button
            v-model="planprocembdatap"
            placeholder="Selecione"
            :disabled="PermiteSalvarPD1 ? false : '' "
          />
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        xl="3"
        class="mb-1"
      >
        <b-form-group
          label="Data Realizado:"
          
        >
          <b-form-datepicker
            id="planprocembdatar" locale="pr-BR"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            today-button
            reset-button
            v-model="planprocembdatar"
            placeholder="Selecione"
            :disabled="PermiteSalvarPD1 ? false : '' "
          />
        </b-form-group>
      </b-col>
  </b-row>
  <b-row>
    <b-col
        md="6"
        xl="12"
        class="mb-1"
      >
        <b-form-group
          label="Observação:"
          
        >
          <b-form-textarea
            id="planprocembdesc"
            placeholder=""
            v-model="planprocembdesc"
            rows="3"
            :readonly="PermiteSalvarPD1 ? false : '' "
          />
        </b-form-group>
      </b-col>
  </b-row>

  <b-row>
    <b-col
        md="6"
        xl="12"
        class="mb-1"
    >
    <b-row>
    <b-col
        md="6"
        xl="8"
        class="mb-1"
    >
     <b-form-file
      truncate-length="50"
      accept=".jpeg, .png, .pfd, .xls, .xlsx, .doc, .docx"
      placeholder="Selecione o arquivo..."
      @change="carregarPlanProcEmb"
      v-model="PlanProcEmb"
      ref="PlanProcEmb-input"
      v-if="PermiteSalvarPD1"
     ></b-form-file>
    </b-col>
    <b-col
        md="6"
        xl="2"
        class="mb-1"
    >
     <b-button  variant="primary"
      v-if="PermiteSalvarPD1"
      type="button" @click="salvarDocumento(PlanProcEmbDescr)"><span>ADICIONAR</span></b-button>

    </b-col>
    </b-row>
    <b-table
      responsive
      class="position-relative"
      :per-page="perPage"
      :current-page="currentPagePlanProcEmb"
      :items="ListPlanProcEmb"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="filter"
      :filter-included-fields="filterOn"
      table-class = "font-size11"
      thead-class = "font-size11"
      thead-tr-class = "font-size11"
    >
    <template #cell(id)="row">
      <div class="DivBotaoLadoLado" >
        <b-button size="sm" @click="DownloadDocumento(row.value, PlanProcEmbDescr)" class="mr-1">
          BAIXAR
        </b-button>
      </div>
      <div class="DivBotaoLadoLado" >
        <b-button
         variant="danger" v-if="PermiteSalvarPD1"
         size="sm" @click="RemoverDocumento(row.value, PlanProcEmbDescr)" class="mr-1">
          REMOVER
        </b-button>
      </div>
    </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <!-- page length -->
      <b-form-group
        label="Registros por Pagina"
        label-cols="8"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="currentPagePlanProcEmb"
          :total-rows="totalRowsPlanProcEmb"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card-body>
    </b-col>
  </b-row>
</b-card-code>

<b-card-code title="Aprovação de mp/insumos/ingredientes">
  <b-row>
      <b-col
        md="6"
        xl="3"
        class="mb-1"
      >
        <b-form-group
          label="Data Planejado:"
          
        >
          <b-form-datepicker
            id="aprovmpinsumingrdatap" locale="pr-BR"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            today-button
            reset-button
            v-model="aprovmpinsumingrdatap"
            placeholder="Selecione"
            :disabled="PermiteSalvarPD1 ? false : '' "
          />
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        xl="3"
        class="mb-1"
      >
        <b-form-group
          label="Data Realizado:"
          
        >
          <b-form-datepicker
            id="aprovmpinsumingrdatar" locale="pr-BR"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            today-button
            reset-button
            v-model="aprovmpinsumingrdatar"
            placeholder="Selecione"
            :disabled="PermiteSalvarPD1 ? false : '' "
          />
        </b-form-group>
      </b-col>
  </b-row>
  <b-row>
    <b-col
        md="6"
        xl="12"
        class="mb-1"
      >
        <b-form-group
          label="Observação:"
          
        >
          <b-form-textarea
            id="aprovmpinsumingrdesc"
            placeholder=""
            v-model="aprovmpinsumingrdesc"
            rows="3"
            :readonly="PermiteSalvarPD1 ? false : '' "
          />
        </b-form-group>
      </b-col>
  </b-row>

  <b-row>
    <b-col
        md="6"
        xl="12"
        class="mb-1"
    >
    <b-row>
    <b-col
        md="6"
        xl="8"
        class="mb-1"
    >
     <b-form-file
      truncate-length="50"
      accept=".jpeg, .png, .pfd, .xls, .xlsx, .doc, .docx"
      placeholder="Selecione o arquivo..."
      @change="carregarAprovMpInsumIngr"
      v-model="AprovMpInsumIngr"
      ref="AprovMpInsumIngr-input"
      v-if="PermiteSalvarPD1"
     ></b-form-file>
    </b-col>
    <b-col
        md="6"
        xl="2"
        class="mb-1"
    >
     <b-button  variant="primary"
      v-if="PermiteSalvarPD1"
      type="button" @click="salvarDocumento(AprovMpInsumIngrDescr)"><span>ADICIONAR</span></b-button>

    </b-col>
    </b-row>
    <b-table
      responsive
      class="position-relative"
      :per-page="perPage"
      :current-page="currentPageAprovMpInsumIngr"
      :items="ListAprovMpInsumIngr"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="filter"
      :filter-included-fields="filterOn"
      table-class = "font-size11"
      thead-class = "font-size11"
      thead-tr-class = "font-size11"
    >
    <template #cell(id)="row">
      <div class="DivBotaoLadoLado" >
        <b-button size="sm" @click="DownloadDocumento(row.value, AprovMpInsumIngrDescr)" class="mr-1">
          BAIXAR
        </b-button>
      </div>
      <div class="DivBotaoLadoLado" >
        <b-button
         variant="danger" v-if="PermiteSalvarPD1"
         size="sm" @click="RemoverDocumento(row.value, AprovMpInsumIngrDescr)" class="mr-1">
          REMOVER
        </b-button>
      </div>
    </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <!-- page length -->
      <b-form-group
        label="Registros por Pagina"
        label-cols="8"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="currentPageAprovMpInsumIngr"
          :total-rows="totalRowsAprovMpInsumIngr"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card-body>
    </b-col>
  </b-row>
</b-card-code>

<b-card-code title="Envio de informações para Homologação de fornecedor">
  <b-row>
      <b-col
        md="6"
        xl="3"
        class="mb-1"
      >
        <b-form-group
          label="Data Planejado:"
          
        >
          <b-form-datepicker
            id="envhomforndatap" locale="pr-BR"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            today-button
            reset-button
            v-model="envhomforndatap"
            placeholder="Selecione"
            :disabled="PermiteSalvarPD1 ? false : '' "
          />
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        xl="3"
        class="mb-1"
      >
        <b-form-group
          label="Data Realizado:"
          
        >
          <b-form-datepicker
            id="envhomforndatar" locale="pr-BR"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            today-button
            reset-button
            v-model="envhomforndatar"
            placeholder="Selecione"
            :disabled="PermiteSalvarPD1 ? false : '' "
          />
        </b-form-group>
      </b-col>
  </b-row>
  <b-row>
    <b-col
        md="6"
        xl="12"
        class="mb-1"
      >
        <b-form-group
          label="Observação:"
          
        >
          <b-form-textarea
            id="envhomforndesc"
            placeholder=""
            v-model="envhomforndesc"
            rows="3"
            :readonly="PermiteSalvarPD1 ? false : '' "
          />
        </b-form-group>
      </b-col>
  </b-row>

  <b-row>
    <b-col
        md="6"
        xl="12"
        class="mb-1"
    >
    <b-row>
    <b-col
        md="6"
        xl="8"
        class="mb-1"
    >
     <b-form-file
      truncate-length="50"
      accept=".jpeg, .png, .pfd, .xls, .xlsx, .doc, .docx"
      placeholder="Selecione o arquivo..."
      @change="carregarEnvHomForn"
      v-model="EnvHomForn"
      ref="EnvHomForn-input"
      v-if="PermiteSalvarPD1"
     ></b-form-file>
    </b-col>
    <b-col
        md="6"
        xl="2"
        class="mb-1"
    >
     <b-button  variant="primary"
      v-if="PermiteSalvarPD1"
      type="button" @click="salvarDocumento(EnvHomFornDescr)"><span>ADICIONAR</span></b-button>

    </b-col>
    </b-row>
    <b-table
      responsive
      class="position-relative"
      :per-page="perPage"
      :current-page="currentPageEnvHomForn"
      :items="ListEnvHomForn"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="filter"
      :filter-included-fields="filterOn"
      table-class = "font-size11"
      thead-class = "font-size11"
      thead-tr-class = "font-size11"
    >
    <template #cell(id)="row">
      <div class="DivBotaoLadoLado" >
        <b-button size="sm" @click="DownloadDocumento(row.value, EnvHomFornDescr)" class="mr-1">
          BAIXAR
        </b-button>
      </div>
      <div class="DivBotaoLadoLado" >
        <b-button
         variant="danger" v-if="PermiteSalvarPD1"
         size="sm" @click="RemoverDocumento(row.value, EnvHomFornDescr)" class="mr-1">
          REMOVER
        </b-button>
      </div>
    </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <!-- page length -->
      <b-form-group
        label="Registros por Pagina"
        label-cols="8"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="currentPageEnvHomForn"
          :total-rows="totalRowsEnvHomForn"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card-body>
    </b-col>
  </b-row>
</b-card-code>

<b-card-code title="Envio de informações para Análise HACCP">
  <b-row>
      <b-col
        md="6"
        xl="3"
        class="mb-1"
      >
        <b-form-group
          label="Data Planejado:"
          
        >
          <b-form-datepicker
            id="envinscanalihaccpdatap" locale="pr-BR"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            today-button
            reset-button
            v-model="envinscanalihaccpdatap"
            placeholder="Selecione"
            :disabled="PermiteSalvarPD1 ? false : '' "
          />
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        xl="3"
        class="mb-1"
      >
        <b-form-group
          label="Data Realizado:"
          
        >
          <b-form-datepicker
            id="envinscanalihaccpdatar" locale="pr-BR"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            today-button
            reset-button
            v-model="envinscanalihaccpdatar"
            placeholder="Selecione"
            :disabled="PermiteSalvarPD1 ? false : '' "
          />
        </b-form-group>
      </b-col>
  </b-row>
  <b-row>
    <b-col
        md="6"
        xl="12"
        class="mb-1"
      >
        <b-form-group
          label="Observação:"
          
        >
          <b-form-textarea
            id="envinscanalihaccpdesc"
            placeholder=""
            v-model="envinscanalihaccpdesc"
            rows="3"
            :readonly="PermiteSalvarPD1 ? false : '' "
          />
        </b-form-group>
      </b-col>
  </b-row>

  <b-row>
    <b-col
        md="6"
        xl="12"
        class="mb-1"
    >
    <b-row>
    <b-col
        md="6"
        xl="8"
        class="mb-1"
    >
     <b-form-file
      truncate-length="50"
      accept=".jpeg, .png, .pfd, .xls, .xlsx, .doc, .docx"
      placeholder="Selecione o arquivo..."
      @change="carregarEnvInscAnaliHACCP"
      v-model="EnvInscAnaliHACCP"
      ref="EnvInscAnaliHACCP-input"
      v-if="PermiteSalvarPD1"
     ></b-form-file>
    </b-col>
    <b-col
        md="6"
        xl="2"
        class="mb-1"
    >
     <b-button  variant="primary" v-if="PermiteSalvarPD1"
      type="button" @click="salvarDocumento(EnvInscAnaliHACCPDescr)"><span>ADICIONAR</span></b-button>

    </b-col>
    </b-row>
    <b-table
      responsive
      class="position-relative"
      :per-page="perPage"
      :current-page="currentPageEnvInscAnaliHACCP"
      :items="ListEnvInscAnaliHACCP"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="filter"
      :filter-included-fields="filterOn"
      table-class = "font-size11"
      thead-class = "font-size11"
      thead-tr-class = "font-size11"
    >
    <template #cell(id)="row">
      <div class="DivBotaoLadoLado" >
        <b-button size="sm" @click="DownloadDocumento(row.value, EnvInscAnaliHACCPDescr)" class="mr-1">
          BAIXAR
        </b-button>
      </div>
      <div class="DivBotaoLadoLado" >
        <b-button
         variant="danger" v-if="PermiteSalvarPD1"
         size="sm" @click="RemoverDocumento(row.value, EnvInscAnaliHACCPDescr)" class="mr-1">
          REMOVER
        </b-button>
      </div>
    </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <!-- page length -->
      <b-form-group
        label="Registros por Pagina"
        label-cols="8"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="currentPageEnvInscAnaliHACCP"
          :total-rows="totalRowsEnvInscAnaliHACCP"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card-body>
    </b-col>
  </b-row>
</b-card-code>

<!-- Ações P&D -->
<b-card-code title="" v-if="NivelEtapa === 1">
   <b-row>
    <b-col
        md="6"
        xl="12"
        class="mb-1"
      >
      <div class="DivBotaoLadoLado" >
       <b-button
      variant="danger"
      type="button"
      @click="$router.push('../DevProduto')"
    >
      <span>RETORNAR</span>
    </b-button>
      </div>
      <div class="DivBotaoLadoLado" >
       <b-button
      variant="danger"
      type="button"
      v-on:click="AbortarProjeto()"
      v-if="PermiteSalvarPD1"
    >
      <span>ABORTAR DESENVOLVIMENTO</span>
    </b-button>
      </div>
      <div class="DivBotaoLadoLado" >
       <b-button
      variant="primary"
      type="button"
      v-on:click="SalvarPD1()"
      v-if="PermiteSalvarPD1"
    >
      <span>SALVAR</span>
    </b-button>
      </div>
      <div class="DivBotaoLadoLado" >
       <b-button
      variant="warning"
      type="button"
      v-on:click="SalvarEnviaHACCP()"
      v-if="PermiteSalvarPD1"
    >
      <span>SALVAR/ENVIAR HACCP</span>
    </b-button>
      </div>
    </b-col>
    </b-row>
</b-card-code>

</div>

<div id="DivHACCP" v-if="NivelEtapa >= 2">
<b-card-code title="Analise de Perigo do Produto">
  <b-row>
      <b-col
        md="6"
        xl="3"
        class="mb-1"
      >
        <b-form-group
          label="Data Planejado:"
          
        >
          <b-form-datepicker
            id="analiseperigproddatap" locale="pr-BR"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            today-button
            reset-button
            v-model="analiseperigproddatap"
            placeholder="Selecione"
            :disabled="PermiteSalvarHACCP ? false : '' "
          />
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        xl="3"
        class="mb-1"
      >
        <b-form-group
          label="Data Realizado:"
          
        >
          <b-form-datepicker
            id="analiseperigproddatar" locale="pr-BR"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            today-button
            reset-button
            v-model="analiseperigproddatar"
            placeholder="Selecione"
            :disabled="PermiteSalvarHACCP ? false : '' "
          />
        </b-form-group>
      </b-col>
  </b-row>
  <b-row>
    <b-col
        md="6"
        xl="12"
        class="mb-1"
      >
        <b-form-group
          label="Observação:"
          
        >
          <b-form-textarea
            id="analiseperigproddesc"
            placeholder=""
            v-model="analiseperigproddesc"
            rows="3"
            :readonly="PermiteSalvarHACCP ? false : '' "
          />
        </b-form-group>
      </b-col>
  </b-row>
</b-card-code>

<!-- Ações HACCP -->
<b-card-code title="" v-if="NivelEtapa === 2">

   <b-row>
    <b-col
        md="6"
        xl="12"
        class="mb-1"
      >
      <div class="DivBotaoLadoLado" >
       <b-button
      variant="danger"
      type="button"
      @click="$router.push('../DevProduto')"
    >
      <span>RETORNAR</span>
    </b-button>
      </div>
      <div class="DivBotaoLadoLado" >
       <b-button
      variant="primary"
      type="button"
      v-on:click="SalvarHACCP()"
      v-if="PermiteSalvarHACCP"
    >
      <span>SALVAR</span>
    </b-button>
      </div>
      <div class="DivBotaoLadoLado" >
       <b-button
      variant="success"
      type="button"
      v-on:click="AprovarHACCP()"
      v-if="PermiteSalvarHACCP"
    >
      <span>APROVAR</span>
    </b-button>
      </div>
      <div class="DivBotaoLadoLado" >
       <b-button
      variant="danger"
      type="button"
      v-on:click="ReprovarHACCP()"
      v-if="PermiteSalvarHACCP"
    >
      <span>REPROVAR</span>
    </b-button>
    </div>
    </b-col>
    </b-row>
</b-card-code>

</div>

<div id="DivPD2" v-if="NivelEtapa >= 3">
<b-card-code title="Teste piloto">
  <b-row>
      <b-col
        md="6"
        xl="3"
        class="mb-1"
      >
        <b-form-group
          label="Data Planejado:"
          
        >
          <b-form-datepicker
            id="testpilotodatap" locale="pr-BR"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            today-button
            reset-button
            v-model="testpilotodatap"
            placeholder="Selecione"
            :disabled="PermiteSalvarPD2 ? false : '' "
          />
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        xl="3"
        class="mb-1"
      >
        <b-form-group
          label="Data Realizado:"
          
        >
          <b-form-datepicker
            id="testpilotodatar" locale="pr-BR"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            today-button
            reset-button
            v-model="testpilotodatar"
            placeholder="Selecione"
            :disabled="PermiteSalvarPD2 ? false : '' "
          />
        </b-form-group>
      </b-col>
  </b-row>
  <b-row>
    <b-col
        md="6"
        xl="12"
        class="mb-1"
      >
        <b-form-group
          label="Observação:"
          
        >
          <b-form-textarea
            id="testpilotodesc"
            placeholder=""
            v-model="testpilotodesc"
            rows="3"
            :readonly="PermiteSalvarPD2 ? false : '' "
          />
        </b-form-group>
      </b-col>
  </b-row>

  <b-row>
    <b-col
        md="6"
        xl="12"
        class="mb-1"
    >
    <b-row>
    <b-col
        md="6"
        xl="8"
        class="mb-1"
    >
     <b-form-file
      truncate-length="50"
      accept=".jpeg, .png, .pfd, .xls, .xlsx, .doc, .docx"
      placeholder="Selecione o arquivo..."
      @change="carregarTestPiloto"
      v-model="TestPiloto"
      ref="TestPiloto-input"
      v-if="PermiteSalvarPD2"
     ></b-form-file>
    </b-col>
    <b-col
        md="6"
        xl="2"
        class="mb-1"
    >
     <b-button  variant="primary"
      v-if="PermiteSalvarPD2"
      type="button" @click="salvarDocumento(TestPilotoDescr)"><span>ADICIONAR</span></b-button>

    </b-col>
    </b-row>
    <b-table
      responsive
      class="position-relative"
      :per-page="perPage"
      :current-page="currentPageTestPiloto"
      :items="ListTestPiloto"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="filter"
      :filter-included-fields="filterOn"
      table-class = "font-size11"
      thead-class = "font-size11"
      thead-tr-class = "font-size11"
    >
    <template #cell(id)="row">
      <div class="DivBotaoLadoLado" >
        <b-button size="sm" @click="DownloadDocumento(row.value, TestPilotoDescr)" class="mr-1">
          BAIXAR
        </b-button>
      </div>
      <div class="DivBotaoLadoLado" >
        <b-button
         variant="danger" v-if="PermiteSalvarPD2"
         size="sm" @click="RemoverDocumento(row.value, TestPilotoDescr)" class="mr-1">
          REMOVER
        </b-button>
      </div>
    </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <!-- page length -->
      <b-form-group
        label="Registros por Pagina"
        label-cols="8"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="currentPageTestPiloto"
          :total-rows="totalRowsTestPiloto"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card-body>
    </b-col>
  </b-row>
</b-card-code>

<b-card-code title="Validação térmica">
  <b-row>
      <b-col
        md="6"
        xl="3"
        class="mb-1"
      >
        <b-form-group
          label="Data Planejado:"
          
        >
          <b-form-datepicker
            id="validtermicdatap" locale="pr-BR"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            today-button
            reset-button
            v-model="validtermicdatap"
            placeholder="Selecione"
            :disabled="PermiteSalvarPD2 ? false : '' "
          />
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        xl="3"
        class="mb-1"
      >
        <b-form-group
          label="Data Realizado:"
          
        >
          <b-form-datepicker
            id="validtermicdatar" locale="pr-BR"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            today-button
            reset-button
            v-model="validtermicdatar"
            placeholder="Selecione"
            :disabled="PermiteSalvarPD2 ? false : '' "
          />
        </b-form-group>
      </b-col>
  </b-row>
  <b-row>
    <b-col
        md="6"
        xl="12"
        class="mb-1"
      >
        <b-form-group
          label="Observação:"
          
        >
          <b-form-textarea
            id="validtermicdesc"
            placeholder=""
            v-model="validtermicdesc"
            rows="3"
            :readonly="PermiteSalvarPD2 ? false : '' "
          />
        </b-form-group>
      </b-col>
  </b-row>

  <b-row>
    <b-col
        md="6"
        xl="12"
        class="mb-1"
    >
    <b-row>
    <b-col
        md="6"
        xl="8"
        class="mb-1"
    >
     <b-form-file
      truncate-length="50"
      accept=".jpeg, .png, .pfd, .xls, .xlsx, .doc, .docx"
      placeholder="Selecione o arquivo..."
      @change="carregarValidTermic"
      v-model="ValidTermic"
      ref="ValidTermic-input"
      v-if="PermiteSalvarPD2"
     ></b-form-file>
    </b-col>
    <b-col
        md="6"
        xl="2"
        class="mb-1"
    >
     <b-button  variant="primary"
      v-if="PermiteSalvarPD2"
      type="button" @click="salvarDocumento(ValidTermicDescr)"><span>ADICIONAR</span></b-button>

    </b-col>
    </b-row>
    <b-table
      responsive
      class="position-relative"
      :per-page="perPage"
      :current-page="currentPageValidTermic"
      :items="ListValidTermic"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="filter"
      :filter-included-fields="filterOn"
      table-class = "font-size11"
      thead-class = "font-size11"
      thead-tr-class = "font-size11"
    >
    <template #cell(id)="row">
      <div class="DivBotaoLadoLado" >
        <b-button size="sm" @click="DownloadDocumento(row.value, ValidTermicDescr)" class="mr-1">
          BAIXAR
        </b-button>
      </div>
      <div class="DivBotaoLadoLado" >
        <b-button
         variant="danger" v-if="PermiteSalvarPD2"
         size="sm" @click="RemoverDocumento(row.value, ValidTermicDescr)" class="mr-1">
          REMOVER
        </b-button>
      </div>
    </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <!-- page length -->
      <b-form-group
        label="Registros por Pagina"
        label-cols="8"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="currentPageValidTermic"
          :total-rows="totalRowsValidTermic"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card-body>
    </b-col>
  </b-row>
</b-card-code>

<b-card-code title="Autoclave">
  <b-row>
      <b-col
        md="6"
        xl="3"
        class="mb-1"
      >
        <b-form-group
          label="Programa de autoclave definido:"
          
        >
           <b-form-input
            id="progautodef"
            placeholder=""
            v-model="progautodef"
            rows="1"
            :readonly="PermiteSalvarPD2 ? false : '' "
          />
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        xl="3"
        class="mb-1"
      >
        <b-form-group
          label="F0 alcançado:"
          
        >
           <b-form-input
            id="f0alcan"
            placeholder=""
            v-model="f0alcan"
            rows="1"
            :readonly="PermiteSalvarPD2 ? false : '' "
          />
        </b-form-group>
      </b-col>
  </b-row>
</b-card-code>

<b-card-code title="Análises microbiológicas">
  <b-row>
      <b-col
        md="6"
        xl="3"
        class="mb-1"
      >
        <b-form-group
          label="Data Planejado:"
          
        >
          <b-form-datepicker
            id="analismicrodatap" locale="pr-BR"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            today-button
            reset-button
            v-model="analismicrodatap"
            placeholder="Selecione"
            :disabled="PermiteSalvarPD2 ? false : '' "
          />
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        xl="3"
        class="mb-1"
      >
        <b-form-group
          label="Data Realizado:"
          
        >
          <b-form-datepicker
            id="analismicrodatar" locale="pr-BR"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            today-button
            reset-button
            v-model="analismicrodatar"
            placeholder="Selecione"
            :disabled="PermiteSalvarPD2 ? false : '' "
          />
        </b-form-group>
      </b-col>
  </b-row>
  <b-row>
    <b-col
        md="6"
        xl="12"
        class="mb-1"
      >
        <b-form-group
          label="Observação:"
          
        >
          <b-form-textarea
            id="analismicrodesc"
            placeholder=""
            v-model="analismicrodesc"
            rows="3"
            :readonly="PermiteSalvarPD2 ? false : '' "
          />
        </b-form-group>
      </b-col>
  </b-row>

  <b-row>
    <b-col
        md="6"
        xl="12"
        class="mb-1"
    >
    <b-row>
    <b-col
        md="6"
        xl="8"
        class="mb-1"
    >
     <b-form-file
      truncate-length="50"
      accept=".jpeg, .png, .pfd, .xls, .xlsx, .doc, .docx"
      placeholder="Selecione o arquivo..."
      @change="carregarAnalisMicro"
      v-model="AnalisMicro"
      ref="AnalisMicro-input"
      v-if="PermiteSalvarPD2"
     ></b-form-file>
    </b-col>
    <b-col
        md="6"
        xl="2"
        class="mb-1"
    >
     <b-button  variant="primary"
      v-if="PermiteSalvarPD2"
      type="button" @click="salvarDocumento(AnalisMicroDescr)"><span>ADICIONAR</span></b-button>

    </b-col>
    </b-row>
    <b-table
      responsive
      class="position-relative"
      :per-page="perPage"
      :current-page="currentPageAnalisMicro"
      :items="ListAnalisMicro"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="filter"
      :filter-included-fields="filterOn"
      table-class = "font-size11"
      thead-class = "font-size11"
      thead-tr-class = "font-size11"
    >
    <template #cell(id)="row">
      <div class="DivBotaoLadoLado" >
        <b-button size="sm" @click="DownloadDocumento(row.value, AnalisMicroDescr)" class="mr-1">
          BAIXAR
        </b-button>
      </div>
      <div class="DivBotaoLadoLado" >
        <b-button
         variant="danger" v-if="PermiteSalvarPD2"
         size="sm" @click="RemoverDocumento(row.value, AnalisMicroDescr)" class="mr-1">
          REMOVER
        </b-button>
      </div>
    </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <!-- page length -->
      <b-form-group
        label="Registros por Pagina"
        label-cols="8"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="currentPageAnalisMicro"
          :total-rows="totalRowsAnalisMicro"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card-body>
    </b-col>
  </b-row>
</b-card-code>

<b-card-code title="Análises físico químicas">
  <b-row>
      <b-col
        md="6"
        xl="3"
        class="mb-1"
      >
        <b-form-group
          label="Data Planejado:"
          
        >
          <b-form-datepicker
            id="analisfisquidatap" locale="pr-BR"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            today-button
            reset-button
            v-model="analisfisquidatap"
            placeholder="Selecione"
            :disabled="PermiteSalvarPD2 ? false : '' "
          />
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        xl="3"
        class="mb-1"
      >
        <b-form-group
          label="Data Realizado:"
          
        >
          <b-form-datepicker
            id="analisfisquidatar" locale="pr-BR"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            today-button
            reset-button
            v-model="analisfisquidatar"
            placeholder="Selecione"
            :disabled="PermiteSalvarPD2 ? false : '' "
          />
        </b-form-group>
      </b-col>
  </b-row>
  <b-row>
    <b-col
        md="6"
        xl="12"
        class="mb-1"
      >
        <b-form-group
          label="Observação:"
          
        >
          <b-form-textarea
            id="analisfisquidesc"
            placeholder=""
            v-model="analisfisquidesc"
            rows="3"
            :readonly="PermiteSalvarPD2 ? false : '' "
          />
        </b-form-group>
      </b-col>
  </b-row>

  <b-row>
    <b-col
        md="6"
        xl="12"
        class="mb-1"
    >
    <b-row>
    <b-col
        md="6"
        xl="8"
        class="mb-1"
    >
     <b-form-file
      truncate-length="50"
      accept=".jpeg, .png, .pfd, .xls, .xlsx, .doc, .docx"
      placeholder="Selecione o arquivo..."
      @change="carregarAnalisFisQui"
      v-model="AnalisFisQui"
      ref="AnalisFisQui-input"
      v-if="PermiteSalvarPD2"
     ></b-form-file>
    </b-col>
    <b-col
        md="6"
        xl="2"
        class="mb-1"
    >
     <b-button  variant="primary"
      v-if="PermiteSalvarPD2"
      type="button" @click="salvarDocumento(AnalisFisQuiDescr)"><span>ADICIONAR</span></b-button>

    </b-col>
    </b-row>
    <b-table
      responsive
      class="position-relative"
      :per-page="perPage"
      :current-page="currentPageAnalisFisQui"
      :items="ListAnalisFisQui"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="filter"
      :filter-included-fields="filterOn"
      table-class = "font-size11"
      thead-class = "font-size11"
      thead-tr-class = "font-size11"
    >
    <template #cell(id)="row">
      <div class="DivBotaoLadoLado" >
        <b-button size="sm" @click="DownloadDocumento(row.value, AnalisFisQuiDescr)" class="mr-1">
          BAIXAR
        </b-button>
      </div>
      <div class="DivBotaoLadoLado" >
        <b-button
         variant="danger" v-if="PermiteSalvarPD2"
         size="sm" @click="RemoverDocumento(row.value, AnalisFisQuiDescr)" class="mr-1">
          REMOVER
        </b-button>
      </div>
    </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <!-- page length -->
      <b-form-group
        label="Registros por Pagina"
        label-cols="8"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="currentPageAnalisFisQui"
          :total-rows="totalRowsAnalisFisQui"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card-body>
    </b-col>
  </b-row>
</b-card-code>

<b-card-code title="Início estudo de Shelf life">
  <b-row>
      <b-col
        md="6"
        xl="3"
        class="mb-1"
      >
        <b-form-group
          label="Data Planejado:"
          
        >
          <b-form-datepicker
            id="incshelflifedatap" locale="pr-BR"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            today-button
            reset-button
            v-model="incshelflifedatap"
            placeholder="Selecione"
            :disabled="PermiteSalvarPD2 ? false : '' "
          />
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        xl="3"
        class="mb-1"
      >
        <b-form-group
          label="Data Realizado:"
          
        >
          <b-form-datepicker
            id="incshelflifedatar" locale="pr-BR"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            today-button
            reset-button
            v-model="incshelflifedatar"
            placeholder="Selecione"
            :disabled="PermiteSalvarPD2 ? false : '' "
          />
        </b-form-group>
      </b-col>
  </b-row>
  <b-row>
    <b-col
        md="6"
        xl="12"
        class="mb-1"
      >
        <b-form-group
          label="Observação:"
          
        >
          <b-form-textarea
            id="incshelflifedesc"
            placeholder=""
            v-model="incshelflifedesc"
            rows="3"
            :readonly="PermiteSalvarPD2 ? false : '' "
          />
        </b-form-group>
      </b-col>
  </b-row>
</b-card-code>

<b-card-code title="Cálculo nutricional">
  <b-row>
      <b-col
        md="6"
        xl="3"
        class="mb-1"
      >
        <b-form-group
          label="Data Planejado:"
          
        >
          <b-form-datepicker
            id="calcnutricionaldatap" locale="pr-BR"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            today-button
            reset-button
            v-model="calcnutricionaldatap"
            placeholder="Selecione"
            :disabled="PermiteSalvarPD2 ? false : '' "
          />
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        xl="3"
        class="mb-1"
      >
        <b-form-group
          label="Data Realizado:"
          
        >
          <b-form-datepicker
            id="calcnutricionaldatar" locale="pr-BR"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            today-button
            reset-button
            v-model="calcnutricionaldatar"
            placeholder="Selecione"
            :disabled="PermiteSalvarPD2 ? false : '' "
          />
        </b-form-group>
      </b-col>
  </b-row>
  <b-row>
    <b-col
        md="6"
        xl="12"
        class="mb-1"
      >
        <b-form-group
          label="Observação:"
          
        >
          <b-form-textarea
            id="calcnutricionaldesc"
            placeholder=""
            v-model="calcnutricionaldesc"
            rows="3"
            :readonly="PermiteSalvarPD2 ? false : '' "
          />
        </b-form-group>
      </b-col>
  </b-row>
</b-card-code>

<b-card-code title="Elaboração de documentos">
  <b-row>
      <b-col
        md="6"
        xl="2"
        class="mb-1"
      >
        <b-form-group
          label="IT:"
          
        >
           <b-form-input
            id="it"
            placeholder=""
            v-model="it"
            rows="1"
            :readonly="PermiteSalvarPD2 ? false : '' "
          />
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        xl="2"
        class="mb-1"
      >
        <b-form-group
          label="ET MP/Insumo:"
          
        >
          <b-form-input
            id="etmpinsumo"
            placeholder=""
            v-model="etmpinsumo"
            rows="1"
            :readonly="PermiteSalvarPD2 ? false : '' "
          />
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        xl="2"
        class="mb-1"
      >
        <b-form-group
          label="ET PA:"
          
        >
          <b-form-input
            id="etpa"
            placeholder=""
            v-model="etpa"
            rows="1"
            :readonly="PermiteSalvarPD2 ? false : '' "
          />
        </b-form-group>
      </b-col>
       <b-col
        md="6"
        xl="2"
        class="mb-1"
      >
        <b-form-group
          label="Croqui de rotulagem:"
          
        >
          <b-form-input
            id="croqrotulagem"
            placeholder=""
            v-model="croqrotulagem"
            rows="1"
            :readonly="PermiteSalvarPD2 ? false : '' "
          />
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        xl="2"
        class="mb-1"
      >
        <b-form-group
          label="Cadastro de produto:"
          
        >
          <b-form-input
            id="cadproduto"
            placeholder=""
            v-model="cadproduto"
            rows="1"
            :readonly="PermiteSalvarPD2 ? false : '' "
          />
        </b-form-group>
      </b-col>
  </b-row>
</b-card-code>

<b-card-code title="Informações para Registo no órgão competente">
  <b-row>
      <b-col
        md="6"
        xl="3"
        class="mb-1"
      >
        <b-form-group
          label="Data Planejado:"
          
        >
          <b-form-datepicker
            id="registroorgcomptdatap" locale="pr-BR"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            today-button
            reset-button
            v-model="registroorgcomptdatap"
            placeholder="Selecione"
            :disabled="PermiteSalvarPD2 ? false : '' "
          />
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        xl="3"
        class="mb-1"
      >
        <b-form-group
          label="Data Realizado:"
          
        >
          <b-form-datepicker
            id="registroorgcomptdatar" locale="pr-BR"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            today-button
            reset-button
            v-model="registroorgcomptdatar"
            placeholder="Selecione"
            :disabled="PermiteSalvarPD2 ? false : '' "
          />
        </b-form-group>
      </b-col>
  </b-row>
  <b-row>
    <b-col
        md="6"
        xl="12"
        class="mb-1"
      >
        <b-form-group
          label="Observação:"
          
        >
          <b-form-textarea
            id="registroorgcomptdesc"
            placeholder=""
            v-model="registroorgcomptdesc"
            rows="3"
            :readonly="PermiteSalvarPD2 ? false : '' "
          />
        </b-form-group>
      </b-col>
  </b-row>
   <b-row>
      <b-col
        md="6"
        xl="6"
        class="mb-1"
      >
        <b-form-group
          label=""
          
        >
        <div>
            <b-form-checkbox inline  name="anvisa" id="anvisa" value="Sim" v-model="anvisa" :disabled="PermiteSalvarPD2 ? false : '' ">Anvisa</b-form-checkbox>
            <b-form-checkbox inline  name="mapa"  id="mapa"  value="Sim" v-model="mapa" :disabled="PermiteSalvarPD2 ? false : '' ">Mapa</b-form-checkbox>
            <b-form-checkbox inline  name="organico" id="organico" value="Sim" v-model="organico" :disabled="PermiteSalvarPD2 ? false : '' ">Organico</b-form-checkbox>
        </div>
        </b-form-group>
      </b-col>
 </b-row>

  <b-row>
    <b-col
        md="6"
        xl="12"
        class="mb-1"
    >
    <b-row>
    <b-col
        md="6"
        xl="8"
        class="mb-1"
    >
     <b-form-file
      truncate-length="50"
      accept=".jpeg, .png, .pfd, .xls, .xlsx, .doc, .docx"
      placeholder="Selecione o arquivo..."
      @change="carregarRegistroOrgCompt"
      v-model="RegistroOrgCompt"
      ref="RegistroOrgCompt-input"
      v-if="PermiteSalvarPD2"
     ></b-form-file>
    </b-col>
    <b-col
        md="6"
        xl="2"
        class="mb-1"
    >
     <b-button  variant="primary"
     v-if="PermiteSalvarPD2"
      type="button" @click="salvarDocumento(RegistroOrgComptDescr)"><span>ADICIONAR</span></b-button>

    </b-col>
    </b-row>
    <b-table
      responsive
      class="position-relative"
      :per-page="perPage"
      :current-page="currentPageRegistroOrgCompt"
      :items="ListRegistroOrgCompt"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="filter"
      :filter-included-fields="filterOn"
      table-class = "font-size11"
      thead-class = "font-size11"
      thead-tr-class = "font-size11"
    >
    <template #cell(id)="row">
      <div class="DivBotaoLadoLado" >
        <b-button size="sm" @click="DownloadDocumento(row.value, RegistroOrgComptDescr)" class="mr-1">
          BAIXAR
        </b-button>
      </div>
      <div class="DivBotaoLadoLado" >
        <b-button
         variant="danger" v-if="PermiteSalvarPD2"
         size="sm" @click="RemoverDocumento(row.value, RegistroOrgComptDescr)" class="mr-1">
          REMOVER
        </b-button>
      </div>
    </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <!-- page length -->
      <b-form-group
        label="Registros por Pagina"
        label-cols="8"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="currentPageRegistroOrgCompt"
          :total-rows="totalRowsRegistroOrgCompt"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card-body>
    </b-col>
  </b-row>
</b-card-code>

<b-card-code title="SGI">
  <b-row>
      <b-col
        md="6"
        xl="3"
        class="mb-1"
      >
        <b-form-group
          label="Documentos disponíveis no SGI:"
          
        >
          <b-form-datepicker
            id="docdispsgi" locale="pr-BR"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            today-button
            reset-button
            v-model="docdispsgi"
            placeholder="Selecione"
            :disabled="PermiteSalvarPD2 ? false : '' "
          />
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        xl="3"
        class="mb-1"
      >
        <b-form-group
          label="Rotulagem disponível no SGI:"
          
        >
          <b-form-datepicker
            id="rotuldispsgi" locale="pr-BR"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            today-button
            reset-button
            v-model="rotuldispsgi"
            placeholder="Selecione"
            :disabled="PermiteSalvarPD2 ? false : '' "
          />
        </b-form-group>
      </b-col>
  </b-row>
</b-card-code>

<b-card-code title="Envio de informações para formação de preço">
  <b-row>
      <b-col
        md="6"
        xl="3"
        class="mb-1"
      >
        <b-form-group
          label="Data Planejado:"
          
        >
          <b-form-datepicker
            id="infformprecodatap" locale="pr-BR"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            today-button
            reset-button
            v-model="infformprecodatap"
            placeholder="Selecione"
            :disabled="PermiteSalvarPD2 ? false : '' "
          />
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        xl="3"
        class="mb-1"
      >
        <b-form-group
          label="Data Realizado:"
          
        >
          <b-form-datepicker
            id="infformprecodatar" locale="pr-BR"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            today-button
            reset-button
            v-model="infformprecodatar"
            placeholder="Selecione"
            :disabled="PermiteSalvarPD2 ? false : '' "
          />
        </b-form-group>
      </b-col>
  </b-row>
  <b-row>
    <b-col
        md="6"
        xl="12"
        class="mb-1"
      >
        <b-form-group
          label="Observação:"
          
        >
          <b-form-textarea
            id="infformprecodesc"
            placeholder=""
            v-model="infformprecodesc"
            rows="3"
            :readonly="PermiteSalvarPD2 ? false : '' "
          />
        </b-form-group>
      </b-col>
  </b-row>

  <b-row>
    <b-col
        md="6"
        xl="12"
        class="mb-1"
    >
    <b-row>
    <b-col
        md="6"
        xl="8"
        class="mb-1"
    >
     <b-form-file
      truncate-length="50"
      accept=".jpeg, .png, .pfd, .xls, .xlsx, .doc, .docx"
      placeholder="Selecione o arquivo..."
      @change="carregarInfFormPreco"
      v-model="InfFormPreco"
      ref="InfFormPreco-input"
      v-if="PermiteSalvarPD2"
     ></b-form-file>
    </b-col>
    <b-col
        md="6"
        xl="2"
        class="mb-1"
    >
     <b-button  variant="primary" v-if="PermiteSalvarPD2"
      type="button" @click="salvarDocumento(InfFormPrecoDescr)"><span>ADICIONAR</span></b-button>

    </b-col>
    </b-row>
    <b-table
      responsive
      class="position-relative"
      :per-page="perPage"
      :current-page="currentPageInfFormPreco"
      :items="ListInfFormPreco"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="filter"
      :filter-included-fields="filterOn"
      table-class = "font-size11"
      thead-class = "font-size11"
      thead-tr-class = "font-size11"
    >
    <template #cell(id)="row">
      <div class="DivBotaoLadoLado" >
        <b-button size="sm" @click="DownloadDocumento(row.value, InfFormPrecoDescr)" class="mr-1">
          BAIXAR
        </b-button>
      </div>
      <div class="DivBotaoLadoLado" >
        <b-button
         variant="danger" v-if="PermiteSalvarPD2"
         size="sm" @click="RemoverDocumento(row.value, InfFormPrecoDescr)" class="mr-1">
          REMOVER
        </b-button>
      </div>
    </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <!-- page length -->
      <b-form-group
        label="Registros por Pagina"
        label-cols="8"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="currentPageInfFormPreco"
          :total-rows="totalRowsInfFormPreco"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card-body>
    </b-col>
  </b-row>
</b-card-code>

<b-card-code title="Treinamento de produto para lideranças">
  <b-row>
      <b-col
        md="6"
        xl="3"
        class="mb-1"
      >
        <b-form-group
          label="Data Planejado:"
          
        >
          <b-form-datepicker
            id="treinprodliddatap" locale="pr-BR"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            today-button
            reset-button
            v-model="treinprodliddatap"
            placeholder="Selecione"
            :disabled="PermiteSalvarPD2 ? false : '' "
          />
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        xl="3"
        class="mb-1"
      >
        <b-form-group
          label="Data Realizado:"
          
        >
          <b-form-datepicker
            id="treinprodliddatar" locale="pr-BR"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            today-button
            reset-button
            v-model="treinprodliddatar"
            placeholder="Selecione"
            :disabled="PermiteSalvarPD2 ? false : '' "
          />
        </b-form-group>
      </b-col>
  </b-row>
  <b-row>
    <b-col
        md="6"
        xl="12"
        class="mb-1"
      >
        <b-form-group
          label="Observação:"
          
        >
          <b-form-textarea
            id="treinprodliddesc"
            placeholder=""
            v-model="treinprodliddesc"
            rows="3"
            :readonly="PermiteSalvarPD2 ? false : '' "
          />
        </b-form-group>
      </b-col>
  </b-row>
</b-card-code>

<b-card-code title="Lançamento: Primeira produção">
  <b-row>
      <b-col
        md="6"
        xl="3"
        class="mb-1"
      >
        <b-form-group
          label="Data Planejado:"
          
        >
          <b-form-datepicker
            id="primeiraproddatap" locale="pr-BR"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            today-button
            reset-button
            v-model="primeiraproddatap"
            placeholder="Selecione"
            :disabled="PermiteSalvarPD2 ? false : '' "
          />
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        xl="3"
        class="mb-1"
      >
        <b-form-group
          label="Data Realizado:"
          
        >
          <b-form-datepicker
            id="primeiraproddatar" locale="pr-BR"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            today-button
            reset-button
            v-model="primeiraproddatar"
            placeholder="Selecione"
            :disabled="PermiteSalvarPD2 ? false : '' "
          />
        </b-form-group>
      </b-col>
  </b-row>
  <b-row>
    <b-col
        md="6"
        xl="12"
        class="mb-1"
      >
        <b-form-group
          label="Observação:"
          
        >
          <b-form-textarea
            id="primeiraproddesc"
            placeholder=""
            v-model="primeiraproddesc"
            rows="3"
            :readonly="PermiteSalvarPD2 ? false : '' "
          />
        </b-form-group>
      </b-col>
  </b-row>
</b-card-code>

<b-card-code title="Lançamento: Segunda produção">
  <b-row>
      <b-col
        md="6"
        xl="3"
        class="mb-1"
      >
        <b-form-group
          label="Data Planejado:"
          
        >
          <b-form-datepicker
            id="segundaproddatap" locale="pr-BR"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            today-button
            reset-button
            v-model="segundaproddatap"
            placeholder="Selecione"
            :disabled="PermiteSalvarPD2 ? false : '' "
          />
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        xl="3"
        class="mb-1"
      >
        <b-form-group
          label="Data Realizado:"
          
        >
          <b-form-datepicker
            id="segundaproddatar" locale="pr-BR"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            today-button
            reset-button
            v-model="segundaproddatar"
            placeholder="Selecione"
            :disabled="PermiteSalvarPD2 ? false : '' "
          />
        </b-form-group>
      </b-col>
  </b-row>
  <b-row>
    <b-col
        md="6"
        xl="12"
        class="mb-1"
      >
        <b-form-group
          label="Observação:"
          
        >
          <b-form-textarea
            id="segundaproddesc"
            placeholder=""
            v-model="segundaproddesc"
            rows="3"
            :readonly="PermiteSalvarPD2 ? false : '' "
          />
        </b-form-group>
      </b-col>
  </b-row>
</b-card-code>

<b-card-code title="Lançamento: Terceira produção">
  <b-row>
      <b-col
        md="6"
        xl="3"
        class="mb-1"
      >
        <b-form-group
          label="Data Planejado:"
          
        >
          <b-form-datepicker
            id="terceiraproddatap" locale="pr-BR"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            today-button
            reset-button
            v-model="terceiraproddatap"
            placeholder="Selecione"
            :disabled="PermiteSalvarPD2 ? false : '' "
          />
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        xl="3"
        class="mb-1"
      >
        <b-form-group
          label="Data Realizado:"
          
        >
          <b-form-datepicker
            id="terceiraproddatar" locale="pr-BR"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            today-button
            reset-button
            v-model="terceiraproddatar"
            placeholder="Selecione"
            :disabled="PermiteSalvarPD2 ? false : '' "
          />
        </b-form-group>
      </b-col>
  </b-row>
  <b-row>
    <b-col
        md="6"
        xl="12"
        class="mb-1"
      >
        <b-form-group
          label="Observação:"
          
        >
          <b-form-textarea
            id="terceiraproddesc"
            placeholder=""
            v-model="terceiraproddesc"
            rows="3"
            :readonly="PermiteSalvarPD2 ? false : '' "
          />
        </b-form-group>
      </b-col>
  </b-row>
</b-card-code>

<b-card-code title="Liçoes Aprendidas">
  <b-row>
      <b-col
        md="6"
        xl="3"
        class="mb-1"
      >
        <b-form-group
          label="Data:"
          
        >
          <b-form-datepicker
            id="licoesaprenddata" locale="pr-BR"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            today-button
            reset-button
            v-model="licoesaprenddata"
            placeholder="Selecione"
            :disabled="PermiteSalvarPD2 ? false : '' "
          />
        </b-form-group>
      </b-col>
  </b-row>
  <b-row>
    <b-col
        md="6"
        xl="12"
        class="mb-1"
      >
        <b-form-group
          label="Observação:"
          
        >
          <b-form-textarea
            id="licoesaprenddesc"
            placeholder=""
            v-model="licoesaprenddesc"
            rows="3"
            :readonly="PermiteSalvarPD2 ? false : '' "
          />
        </b-form-group>
      </b-col>
  </b-row>
</b-card-code>

<!-- Ações P&D(2) -->
<b-card-code title="" v-if="NivelEtapa === 3">
  <b-row>
    <b-col
        md="6"
        xl="3"
        class="mb-1"
      >
        <b-form-group
          label="Situação do Produto:"
          
        >
          <b-form-select
          v-model="statusworkflowid"
          :options="statusworkflow"
          required>
          </b-form-select>
        </b-form-group>
      </b-col>
  </b-row>
   <b-row>
     <b-col
        md="6"
        xl="8"
        class="mb-1"
      >
      <div class="DivBotaoLadoLado" >
       <b-button
      variant="danger"
      type="button"
      @click="$router.push('../DevProduto')"
    >
      <span>RETORNAR</span>
    </b-button>
  </div>
      <div class="DivBotaoLadoLado" >
    <b-button
      variant="success"
      type="button"
      v-on:click="SalvarPD2()"
    >
      <span>SALVAR</span>
    </b-button>
      </div>
    </b-col>
    </b-row>
</b-card-code>

</div>

<b-card-code title="" v-if="NivelEtapa === 99">
   <b-row>
    <b-col
        class="mb-1"
      >
       <b-button
      variant="danger"
      type="button"
      @click="$router.push('../DevProduto')"
    >
      <span>RETORNAR</span>
    </b-button>
    </b-col>
    </b-row>
</b-card-code>

</form>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
  BFormInput, BRow, BCol, BFormGroup, BButton, BFormDatepicker,
  BFormTextarea, BFormCheckbox, BFormRadioGroup,
  BFormSelect, BFormFile, BTable, BPagination, BCardBody,
} from 'bootstrap-vue'
import apiPortalSky from '@/services/apiPortalSky'
import AlertaMsg from '@/customPortal/AlertaMsg.vue'
import ConexaoAPI from '@/customPortal/ConexaoAPI.vue'

export default {
  components: {
    BCardCode,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BButton,
    BFormDatepicker,
    BFormTextarea,
    BFormCheckbox,
    BFormRadioGroup,
    BFormSelect,
    BFormFile,
    BTable,
    BPagination,
    BCardBody,
  },
  data() {
    return {
      PermiteSalvarPD1: false,
      PermiteSalvarHACCP: false,
      PermiteSalvarPD2: false,
      Etapa: '',
      NivelEtapa: 0,
      notification: {
      },
      Desenvolvimento: {
      },
      solidesenvolvimentoid: 0,
      numsoliproduto: '',
      produto: '',
      datainicdesenvolvimento: '',
      plandesdatap: '',
      plandesdatar: '',
      plandesdesc: '',
      pesqmatpriingdatap: '',
      pesqmatpriingdatar: '',
      pesqmatpriingdesc: '',
      testformdatap: '',
      testformdatar: '',
      testformdesc: '',
      envioamostradatap: '',
      envioamostradatar: '',
      envioamostradesc: '',
      aprovamostclient: '',
      aprovamostclientdesc: '',
      envioinfestimcustodatap: '',
      envioinfestimcustodatar: '',
      envioinfestimcustodesc: '',
      estimcustorskgdatap: '',
      estimcustorskgdatar: '',
      estimcustorskgdesc: '',
      planprocembdatap: '',
      planprocembdatar: '',
      planprocembdesc: '',
      aprovmpinsumingrdatap: '',
      aprovmpinsumingrdatar: '',
      aprovmpinsumingrdesc: '',
      envhomforndatap: '',
      envhomforndatar: '',
      envhomforndesc: '',
      envinscanalihaccpdatap: '',
      envinscanalihaccpdatar: '',
      envinscanalihaccpdesc: '',
      analiseperigproddatap: '',
      analiseperigproddatar: '',
      analiseperigproddesc: '',
      testpilotodatap: '',
      testpilotodatar: '',
      testpilotodesc: '',
      validtermicdatap: '',
      validtermicdatar: '',
      validtermicdesc: '',
      progautodef: '',
      f0alcan: '',
      analismicrodatap: '',
      analismicrodatar: '',
      analismicrodesc: '',
      analisfisquidatap: '',
      analisfisquidatar: '',
      analisfisquidesc: '',
      incshelflifedatap: '',
      incshelflifedatar: '',
      incshelflifedesc: '',
      calcnutricionaldatap: '',
      calcnutricionaldatar: '',
      calcnutricionaldesc: '',
      it: '',
      etmpinsumo: '',
      etpa: '',
      croqrotulagem: '',
      cadproduto: '',
      registroorgcomptdatap: '',
      registroorgcomptdatar: '',
      registroorgcomptdesc: '',
      anvisa: '',
      mapa: '',
      organico: '',
      docdispsgi: '',
      rotuldispsgi: '',
      infformprecodatap: '',
      infformprecodatar: '',
      infformprecodesc: '',
      treinprodliddatap: '',
      treinprodliddatar: '',
      treinprodliddesc: '',
      primeiraproddatap: '',
      primeiraproddatar: '',
      primeiraproddesc: '',
      segundaproddatap: '',
      segundaproddatar: '',
      segundaproddesc: '',
      terceiraproddatap: '',
      terceiraproddatar: '',
      terceiraproddesc: '',
      licoesaprenddata: '',
      licoesaprenddesc: '',
      statusworkflowid: 0,
      tipoalteracao: '',
      acessoetapa: 0,
      statusworkflow: [
      ],
      // Pesquisa de matérias primas
      ListPesqMatPriIng: [],
      PesqMatPriIng: null,
      PesqMatPriIngBase64: null,
      PesqMatPriIngDescr: 'Pesquisa de matérias primas',
      currentPagePesqMatPriIng: 1,
      totalRowsPesqMatPriIng: 1,
      // Teste de Formulação
      ListTestForm: [],
      TestForm: null,
      TestFormBase64: null,
      TestFormDescr: 'Teste de Formulação',
      currentPageTestForm: 1,
      totalRowsTestForm: 1,
      // Envio Amostra
      ListEnvioAmostra: [],
      EnvioAmostra: null,
      EnvioAmostraBase64: null,
      EnvioAmostraDescr: 'Envio Amostra',
      currentPageEnvioAmostra: 1,
      totalRowsEnvioAmostra: 1,
      // Aprovação da amostra pelo cliente
      ListAprovAmostClient: [],
      AprovAmostClient: null,
      AprovAmostClientBase64: null,
      AprovAmostClientDescr: 'Aprovação da amostra pelo cliente',
      currentPageAprovAmostClient: 1,
      totalRowsAprovAmostClient: 1,
      // Envio de informações para estimativa de custo
      ListEnvioInfEstimCusto: [],
      EnvioInfEstimCusto: null,
      EnvioInfEstimCustoBase64: null,
      EnvioInfEstimCustoDescr: 'Envio de informações para estimativa de custo',
      currentPageEnvioInfEstimCusto: 1,
      totalRowsEnvioInfEstimCusto: 1,
      // Planejamento do processo/embalagens
      ListPlanProcEmb: [],
      PlanProcEmb: null,
      PlanProcEmbBase64: null,
      PlanProcEmbDescr: 'Planejamento do processo/embalagens',
      currentPagePlanProcEmb: 1,
      totalRowsPlanProcEmb: 1,
      // Aprovação de mp/insumos/ingredientes
      ListAprovMpInsumIngr: [],
      AprovMpInsumIngr: null,
      AprovMpInsumIngrBase64: null,
      AprovMpInsumIngrDescr: 'Aprovação de mp/insumos/ingredientes',
      currentPageAprovMpInsumIngr: 1,
      totalRowsAprovMpInsumIngr: 1,
      // Envio de informações para Homologação de fornecedor
      ListEnvHomForn: [],
      EnvHomForn: null,
      EnvHomFornBase64: null,
      EnvHomFornDescr: 'Envio de informações para Homologação de fornecedor',
      currentPageEnvHomForn: 1,
      totalRowsEnvHomForn: 1,
      // Envio de informações para Análise HACCP
      ListEnvInscAnaliHACCP: [],
      EnvInscAnaliHACCP: null,
      EnvInscAnaliHACCPBase64: null,
      EnvInscAnaliHACCPDescr: 'Envio de informações para Análise HACCP',
      currentPageEnvInscAnaliHACCP: 1,
      totalRowsEnvInscAnaliHACCP: 1,
      // Teste piloto
      ListTestPiloto: [],
      TestPiloto: null,
      TestPilotoBase64: null,
      TestPilotoDescr: 'Teste piloto',
      currentPageTestPiloto: 1,
      totalRowsTestPiloto: 1,
      // Validação térmica
      ListValidTermic: [],
      ValidTermic: null,
      ValidTermicBase64: null,
      ValidTermicDescr: 'Validação térmica',
      currentPageValidTermic: 1,
      totalRowsValidTermic: 1,
      // Análises microbiológicas
      ListAnalisMicro: [],
      AnalisMicro: null,
      AnalisMicroBase64: null,
      AnalisMicroDescr: 'Análises microbiológicas',
      currentPageAnalisMicro: 1,
      totalRowsAnalisMicro: 1,
      // Análises físico químicas
      ListAnalisFisQui: [],
      AnalisFisQui: null,
      AnalisFisQuiBase64: null,
      AnalisFisQuiDescr: 'Análises físico químicas',
      currentPageAnalisFisQui: 1,
      totalRowsAnalisFisQui: 1,
      // Informações para Registo no órgão competente
      ListRegistroOrgCompt: [],
      RegistroOrgCompt: null,
      RegistroOrgComptBase64: null,
      RegistroOrgComptDescr: 'Informações para Registo no órgão competente',
      currentPageRegistroOrgCompt: 1,
      totalRowsRegistroOrgCompt: 1,
      // Envio de informações para formação de preço
      ListInfFormPreco: [],
      InfFormPreco: null,
      InfFormPrecoBase64: null,
      InfFormPrecoDescr: 'Envio de informações para formação de preço',
      currentPageInfFormPreco: 1,
      totalRowsInfFormPreco: 1,
      // Configuração de Tabelas Geral
      perPage: 2,
      pageOptions: [2, 5, 10, 50],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      fields: [
        { key: 'docnome', label: 'Documento' },
        { key: 'id', label: 'Ações' },
      ],
    }
  },
  methods: {
    async AbortarProjeto() {
      this.statusworkflowid = 11 // PRODUTO ABORTADO
      await this.SoliDesenvolvimentoAlteraStatus()
      if (this.notification.alert_type === 'success') {
        this.notification.message = 'Desenvolvimento Abortado'
        this.$session.set('notification', this.notification)
        this.$router.push('../DevProduto')
      } else {
        AlertaMsg.FormataMsg(this.notification)
      }
    },
    async SoliDesenvolvimentoAlteraStatus() {
      this.notification = { message: '', alert_type: 'error' }
      const usuario = this.$session.get('usuario')
      const URLpost = `/SoliDesenvolvimento/SoliDesenvolvimentoAlteraStatus?CODUSU=${usuario.codusu}`
      this.CarregaDadosNoObjeto() // Carrega os Dados no Objeto para enviar no Parametro
      const ParametroJson = JSON.parse(JSON.stringify(this.Desenvolvimento))
      await apiPortalSky.post(URLpost, ParametroJson, {
        headers: {
          'Content-Type': 'application/json',
        },
      }).then(response => {
        if (response.status === 200) {
          this.notification = { message: '', alert_type: 'success' }
        } else {
          this.notification = { message: 'Operação não Realizada', alert_type: 'error' }
        }
      })
        .catch(error => {
          this.notification = ConexaoAPI.ValidaErro(error)
        })
    },
    async SalvarPD1() {
      this.tipoalteracao = 'P&D_1'
      await this.SalvarFormularioDesenvolvimentoEtapas()
      if (this.notification.alert_type === 'success') {
        this.notification.message = 'Alteração Realizada com Sucesso'
        this.$session.set('notification', this.notification)
        this.$router.push('../DevProduto')
      } else {
        AlertaMsg.FormataMsg(this.notification)
      }
    },
    async SalvarPD2() {
      this.tipoalteracao = 'P&D_2'
      await this.SalvarFormularioDesenvolvimentoEtapas()
      if (this.notification.alert_type === 'success') {
        this.notification.message = 'Alteração Realizada com Sucesso'
        this.$session.set('notification', this.notification)
        this.$router.push('../DevProduto')
      } else {
        AlertaMsg.FormataMsg(this.notification)
      }
    },
    async SalvarEnviaHACCP() {
      this.tipoalteracao = 'P&D_1'
      await this.SalvarFormularioDesenvolvimentoEtapas()
      if (this.notification.alert_type === 'success') {
        this.statusworkflowid = 5 // ANALISE HACCP
        await this.SoliDesenvolvimentoAlteraStatus()
        if (this.notification.alert_type === 'success') {
          this.notification.message = 'Enviado para HACCP com Sucesso'
          this.$session.set('notification', this.notification)
          this.$router.push('../DevProduto')
        } else {
          AlertaMsg.FormataMsg(this.notification)
        }
      } else {
        AlertaMsg.FormataMsg(this.notification)
      }
    },
    async SalvarHACCP() {
      this.tipoalteracao = 'HACCP'
      await this.SalvarFormularioDesenvolvimentoEtapas()
      if (this.notification.alert_type === 'success') {
        this.notification.message = 'Alteração Realizada com Sucesso'
        this.$session.set('notification', this.notification)
        this.$router.push('../DevProduto')
      } else {
        AlertaMsg.FormataMsg(this.notification)
      }
    },
    async AprovarHACCP() {
      this.tipoalteracao = 'HACCP'
      await this.SalvarFormularioDesenvolvimentoEtapas()
      if (this.notification.alert_type === 'success') {
        this.statusworkflowid = 7 // DESENVOLVIMENTO
        await this.SoliDesenvolvimentoAlteraStatus()
        if (this.notification.alert_type === 'success') {
          this.notification.message = 'Enviado para o P&D dar sequencia no processo de Desenvolvimento'
          this.$session.set('notification', this.notification)
          this.$router.push('../DevProduto')
        } else {
          AlertaMsg.FormataMsg(this.notification)
        }
      } else {
        AlertaMsg.FormataMsg(this.notification)
      }
    },
    async ReprovarHACCP() {
      this.tipoalteracao = 'HACCP'
      await this.SalvarFormularioDesenvolvimentoEtapas()
      if (this.notification.alert_type === 'success') {
        this.statusworkflowid = 3 // PLANEJAMENTO
        await this.SoliDesenvolvimentoAlteraStatus()
        if (this.notification.alert_type === 'success') {
          this.notification.message = 'Enviado para Planejamento'
          this.$session.set('notification', this.notification)
          this.$router.push('../DevProduto')
        } else {
          AlertaMsg.FormataMsg(this.notification)
        }
      } else {
        AlertaMsg.FormataMsg(this.notification)
      }
    },
    async SalvarFormularioDesenvolvimentoEtapas() {
      this.notification = { message: '', alert_type: '' }
      const usuario = this.$session.get('usuario')
      const URLpost = `/SoliDesenvolvimento/SoliDesenvolvimentoEtapas?CODUSU=${usuario.codusu}`
      this.CarregaDadosNoObjeto() // Carrega os Dados no Objeto para enviar no Parametro
      const ParametroJson = JSON.parse(JSON.stringify(this.Desenvolvimento))
      await apiPortalSky.post(URLpost, ParametroJson, {
        headers: {
          'Content-Type': 'application/json',
        },
      }).then(response => {
        if (response.status === 200) {
          this.notification = { message: '', alert_type: 'success' }
        } else {
          this.notification = { message: 'Operação não Realizada', alert_type: 'error' }
        }
      })
        .catch(error => {
          this.notification = ConexaoAPI.ValidaErro(error)
        })
    },
    async MostraFormularioSolicitacao($SOLIDESENVOLVIMENTOID) {
      let notification = ''
      const usuario = this.$session.get('usuario')
      const SOLIDESENVOLVIMENTOID = $SOLIDESENVOLVIMENTOID
      const ETAPA = 'FormularioDesenvolvimento'
      const URLpost = `/SoliDesenvolvimento/SoliDesenvolvimentoMostra?CODUSU=${usuario.codusu}&SOLIDESENVOLVIMENTOID=${SOLIDESENVOLVIMENTOID}&ETAPA=${ETAPA}`
      await apiPortalSky.post(URLpost, SOLIDESENVOLVIMENTOID).then(response => {
        if (response.status === 200) {
          this.Desenvolvimento = response.data
          this.DescarregaDadosDoObjeto()
          this.ValidaAcesso()
        }
      }).catch(error => {
        notification = ConexaoAPI.ValidaErro(error)
        AlertaMsg.FormataMsg(notification)
      })
    },
    ValidaAcesso() {
      switch (this.statusworkflowid) {
        case 3: // ETP-01 - PLANEJAMENTO
          this.Etapa = 'P&D_1'
          this.NivelEtapa = 1
          if (this.acessoetapa === 1) {
            this.PermiteSalvarPD1 = true
          } else {
            this.PermiteSalvarPD1 = false
          }
          break
        case 5: // ETP-02 - ANALISE HACCP
          this.Etapa = 'HACCP'
          this.NivelEtapa = 2
          if (this.acessoetapa === 1) {
            this.PermiteSalvarHACCP = true
          } else {
            this.PermiteSalvarHACCP = false
          }
          break
        case 7: // ETP-03 - DESENVOLVIMENTO
          this.Etapa = 'P&D_2'
          this.NivelEtapa = 3
          if (this.acessoetapa === 1) {
            this.PermiteSalvarPD2 = true
          } else {
            this.PermiteSalvarPD2 = false
          }
          break
        default:
          this.PermiteSalvarPD1 = false
          this.PermiteSalvarHACCP = false
          this.PermiteSalvarPD2 = false
          this.NivelEtapa = 99
          break
      }
    },
    async ConsultaStatusWorkFlow() {
      let notification = ''
      const usuario = this.$session.get('usuario')
      const STATUSWORKFLOWLOCID = '1' // PSKYSOLDEV
      const URLpost = `/StatusWorkFlow/StatusWorkFlow?CODUSU=${usuario.codusu}&STATUSWORKFLOWLOCID=${STATUSWORKFLOWLOCID}`
      await apiPortalSky.post(URLpost, STATUSWORKFLOWLOCID).then(response => {
        if (response.status === 200) {
          response.data.forEach((value, index) => {
            const Opcao = { sequencia: index, value: value.statusworkflowid, text: value.descricao }
            this.statusworkflow.push(Opcao)
          })
        }
      }).catch(error => {
        notification = ConexaoAPI.ValidaErro(error)
        AlertaMsg.FormataMsg(notification)
      })
    },
    carregarPesqMatPriIng(e) {
      this.carregarDocumento(e, this.PesqMatPriIngDescr)
    },
    carregarTestForm(e) {
      this.carregarDocumento(e, this.TestFormDescr)
    },
    carregarEnvioAmostra(e) {
      this.carregarDocumento(e, this.EnvioAmostraDescr)
    },
    carregarAprovAmostClient(e) {
      this.carregarDocumento(e, this.AprovAmostClientDescr)
    },
    carregarEnvioInfEstimCusto(e) {
      this.carregarDocumento(e, this.EnvioInfEstimCustoDescr)
    },
    carregarPlanProcEmb(e) {
      this.carregarDocumento(e, this.PlanProcEmbDescr)
    },
    carregarAprovMpInsumIngr(e) {
      this.carregarDocumento(e, this.AprovMpInsumIngrDescr)
    },
    carregarEnvHomForn(e) {
      this.carregarDocumento(e, this.EnvHomFornDescr)
    },
    carregarEnvInscAnaliHACCP(e) {
      this.carregarDocumento(e, this.EnvInscAnaliHACCPDescr)
    },
    carregarTestPiloto(e) {
      this.carregarDocumento(e, this.TestPilotoDescr)
    },
    carregarValidTermic(e) {
      this.carregarDocumento(e, this.ValidTermicDescr)
    },
    carregarAnalisMicro(e) {
      this.carregarDocumento(e, this.AnalisMicroDescr)
    },
    carregarAnalisFisQui(e) {
      this.carregarDocumento(e, this.AnalisFisQuiDescr)
    },
    carregarRegistroOrgCompt(e) {
      this.carregarDocumento(e, this.RegistroOrgComptDescr)
    },
    carregarInfFormPreco(e) {
      this.carregarDocumento(e, this.InfFormPrecoDescr)
    },
    carregarDocumento(e, $origem) {
      const comp = this
      const img = e.target.files[0]
      const promise = new Promise(resolve => {
        const reader = new FileReader()
        reader.onload = function () {
          resolve(reader.result)
        }
        reader.readAsDataURL(img)
      })
      promise.then(img2 => {
        comp.srcImg = img2
        if ($origem === this.PesqMatPriIngDescr) {
          this.PesqMatPriIngBase64 = comp.srcImg.replace('data:', '').replace(/^.+,/, '')
        }
        if ($origem === this.TestFormDescr) {
          this.TestFormBase64 = comp.srcImg.replace('data:', '').replace(/^.+,/, '')
        }
        if ($origem === this.EnvioAmostraDescr) {
          this.EnvioAmostraBase64 = comp.srcImg.replace('data:', '').replace(/^.+,/, '')
        }
        if ($origem === this.AprovAmostClientDescr) {
          this.AprovAmostClientBase64 = comp.srcImg.replace('data:', '').replace(/^.+,/, '')
        }
        if ($origem === this.EnvioInfEstimCustoDescr) {
          this.EnvioInfEstimCustoBase64 = comp.srcImg.replace('data:', '').replace(/^.+,/, '')
        }
        if ($origem === this.PlanProcEmbDescr) {
          this.PlanProcEmbBase64 = comp.srcImg.replace('data:', '').replace(/^.+,/, '')
        }
        if ($origem === this.AprovMpInsumIngrDescr) {
          this.AprovMpInsumIngrBase64 = comp.srcImg.replace('data:', '').replace(/^.+,/, '')
        }
        if ($origem === this.EnvHomFornDescr) {
          this.EnvHomFornBase64 = comp.srcImg.replace('data:', '').replace(/^.+,/, '')
        }
        if ($origem === this.EnvInscAnaliHACCPDescr) {
          this.EnvInscAnaliHACCPBase64 = comp.srcImg.replace('data:', '').replace(/^.+,/, '')
        }
        if ($origem === this.TestPilotoDescr) {
          this.TestPilotoBase64 = comp.srcImg.replace('data:', '').replace(/^.+,/, '')
        }
        if ($origem === this.ValidTermicDescr) {
          this.ValidTermicBase64 = comp.srcImg.replace('data:', '').replace(/^.+,/, '')
        }
        if ($origem === this.AnalisMicroDescr) {
          this.AnalisMicroBase64 = comp.srcImg.replace('data:', '').replace(/^.+,/, '')
        }
        if ($origem === this.AnalisFisQuiDescr) {
          this.AnalisFisQuiBase64 = comp.srcImg.replace('data:', '').replace(/^.+,/, '')
        }
        if ($origem === this.RegistroOrgComptDescr) {
          this.RegistroOrgComptBase64 = comp.srcImg.replace('data:', '').replace(/^.+,/, '')
        }
        if ($origem === this.InfFormPrecoDescr) {
          this.InfFormPrecoBase64 = comp.srcImg.replace('data:', '').replace(/^.+,/, '')
        }
      })
    },
    RemoverDocumento(id, $origem) {
      
      const listaSoliDesenvolvimentoAnexo = [] // Lista Temporaria
      this.ListPesqMatPriIng = []
      this.ListTestForm = []
      this.ListEnvioAmostra = []
      this.ListAprovAmostClient = []
      this.ListEnvioInfEstimCusto = []
      this.ListPlanProcEmb = []
      this.ListAprovMpInsumIngr = []
      this.ListEnvHomForn = []
      this.ListEnvInscAnaliHACCP = []
      this.ListTestPiloto = []
      this.ListValidTermic = []
      this.ListAnalisMicro = []
      this.ListAnalisFisQui = []
      this.ListRegistroOrgCompt = []
      this.ListInfFormPreco = []

      // Percorre Anexos
      this.Desenvolvimento.listaSoliDesenvolvimentoAnexo.forEach(Documento => {
        const DocumentoValidacao = Documento
        
        // Se Difente do selecionado para Remover add a Lista
        if ((DocumentoValidacao.id !== id 
          && Documento.origem === $origem 
          && DocumentoValidacao.tipooperacao !== 'REMOVER') || Documento.origem !== $origem) {
          if (Documento.origem === this.PesqMatPriIngDescr) {
            this.ListPesqMatPriIng.push(DocumentoValidacao)
          }
          if (Documento.origem === this.TestFormDescr) {
            this.ListTestForm.push(DocumentoValidacao)
          }
          if (Documento.origem === this.EnvioAmostraDescr) {
            this.ListEnvioAmostra.push(DocumentoValidacao)
          }
          if (Documento.origem === this.AprovAmostClientDescr) {
            this.ListAprovAmostClient.push(DocumentoValidacao)
          }
          if (Documento.origem === this.EnvioInfEstimCustoDescr) {
            this.ListEnvioInfEstimCusto.push(DocumentoValidacao)
          }
          if (Documento.origem === this.PlanProcEmbDescr) {
            this.ListPlanProcEmb.push(DocumentoValidacao)
          }
          if (Documento.origem === this.AprovMpInsumIngrDescr) {
            this.ListAprovMpInsumIngr.push(DocumentoValidacao)
          }
          if (Documento.origem === this.EnvHomFornDescr) {
            this.ListEnvHomForn.push(DocumentoValidacao)
          }
          if (Documento.origem === this.EnvInscAnaliHACCPDescr) {
            this.ListEnvInscAnaliHACCP.push(DocumentoValidacao)
          }
          if (Documento.origem === this.TestPilotoDescr) {
            this.ListTestPiloto.push(DocumentoValidacao)
          }
          if (Documento.origem === this.ValidTermicDescr) {
            this.ListValidTermic.push(DocumentoValidacao)
          }
          if (Documento.origem === this.AnalisMicroDescr) {
            this.ListAnalisMicro.push(DocumentoValidacao)
          }
          if (Documento.origem === this.AnalisFisQuiDescr) {
            this.ListAnalisFisQui.push(DocumentoValidacao)
          }
          if (Documento.origem === this.RegistroOrgComptDescr) {
            this.ListRegistroOrgCompt.push(DocumentoValidacao)
          }
          if (Documento.origem === this.InfFormPrecoDescr) {
            this.ListInfFormPreco.push(DocumentoValidacao)
          }
          listaSoliDesenvolvimentoAnexo.push(DocumentoValidacao)
        } else if (DocumentoValidacao.solidesenvolvimentoanexoid !== 0 && Documento.origem === $origem) {
          // Se tiver ID, marca como Remover
          DocumentoValidacao.tipooperacao = 'REMOVER'
          listaSoliDesenvolvimentoAnexo.push(DocumentoValidacao)
        }
      })
      
      this.AtualizaTotalRows()
      this.Desenvolvimento.listaSoliDesenvolvimentoAnexo = []
      this.Desenvolvimento.listaSoliDesenvolvimentoAnexo = listaSoliDesenvolvimentoAnexo
    },
    salvarDocumento($origem) {
      if (this.PesqMatPriIng != null && $origem === this.PesqMatPriIngDescr) {
        let maxId = 1
        if (this.ListPesqMatPriIng.length > 0) {
          maxId += this.ListPesqMatPriIng.reduce(
            (max, item) => (item.id > max ? item.id : max),
            this.ListPesqMatPriIng[0].id,
          )
        }
        this.$refs['PesqMatPriIng-input'].reset() // Limpa input
        const documento = {
          id: maxId,
          solidesenvolvimentoanexoid: 0,
          docnome: this.PesqMatPriIng.name,
          extensao: this.PesqMatPriIng.type,
          docimage: this.PesqMatPriIngBase64,
          origem: $origem,
          tipooperacao: 'INCLUIR',
        }
        if (this.Desenvolvimento.listaSoliDesenvolvimentoAnexo == null) {
          this.Desenvolvimento.listaSoliDesenvolvimentoAnexo = []
        }
        this.Desenvolvimento.listaSoliDesenvolvimentoAnexo.push(documento)
        this.ListPesqMatPriIng.push(documento)
        this.totalRowsPesqMatPriIng = this.ListPesqMatPriIng.length
        this.PesqMatPriIng = null
      }

      if (this.TestForm != null && $origem === this.TestFormDescr) {
        let maxId = 1
        if (this.ListTestForm.length > 0) {
          maxId += this.ListTestForm.reduce(
            (max, item) => (item.id > max ? item.id : max),
            this.ListTestForm[0].id,
          )
        }
        this.$refs['TestForm-input'].reset() // Limpa input
        const documento = {
          id: maxId,
          solidesenvolvimentoanexoid: 0,
          docnome: this.TestForm.name,
          extensao: this.TestForm.type,
          docimage: this.TestFormBase64,
          origem: $origem,
          tipooperacao: 'INCLUIR',
        }
        if (this.Desenvolvimento.listaSoliDesenvolvimentoAnexo == null) {
          this.Desenvolvimento.listaSoliDesenvolvimentoAnexo = []
        }
        this.Desenvolvimento.listaSoliDesenvolvimentoAnexo.push(documento)
        this.ListTestForm.push(documento)
        this.totalRowsTestForm = this.ListTestForm.length
        this.TestForm = null
      }

      if (this.EnvioAmostra != null && $origem === this.EnvioAmostraDescr) {
        let maxId = 1
        if (this.ListEnvioAmostra.length > 0) {
          maxId += this.ListEnvioAmostra.reduce(
            (max, item) => (item.id > max ? item.id : max),
            this.ListEnvioAmostra[0].id,
          )
        }
        this.$refs['EnvioAmostra-input'].reset() // Limpa input
        const documento = {
          id: maxId,
          solidesenvolvimentoanexoid: 0,
          docnome: this.EnvioAmostra.name,
          extensao: this.EnvioAmostra.type,
          docimage: this.EnvioAmostraBase64,
          origem: $origem,
          tipooperacao: 'INCLUIR',
        }
        if (this.Desenvolvimento.listaSoliDesenvolvimentoAnexo == null) {
          this.Desenvolvimento.listaSoliDesenvolvimentoAnexo = []
        }
        this.Desenvolvimento.listaSoliDesenvolvimentoAnexo.push(documento)
        this.ListEnvioAmostra.push(documento)
        this.totalRowsEnvioAmostra = this.ListEnvioAmostra.length
        this.EnvioAmostra = null
      }

      if (this.AprovAmostClient != null && $origem === this.AprovAmostClientDescr) {
        let maxId = 1
        if (this.ListAprovAmostClient.length > 0) {
          maxId += this.ListAprovAmostClient.reduce(
            (max, item) => (item.id > max ? item.id : max),
            this.ListAprovAmostClient[0].id,
          )
        }
        this.$refs['AprovAmostClient-input'].reset() // Limpa input
        const documento = {
          id: maxId,
          solidesenvolvimentoanexoid: 0,
          docnome: this.AprovAmostClient.name,
          extensao: this.AprovAmostClient.type,
          docimage: this.AprovAmostClientBase64,
          origem: $origem,
          tipooperacao: 'INCLUIR',
        }
        if (this.Desenvolvimento.listaSoliDesenvolvimentoAnexo == null) {
          this.Desenvolvimento.listaSoliDesenvolvimentoAnexo = []
        }
        this.Desenvolvimento.listaSoliDesenvolvimentoAnexo.push(documento)
        this.ListAprovAmostClient.push(documento)
        this.totalRowsAprovAmostClient = this.ListAprovAmostClient.length
        this.AprovAmostClient = null
      }

      if (this.EnvioInfEstimCusto != null && $origem === this.EnvioInfEstimCustoDescr) {
        let maxId = 1
        if (this.ListEnvioInfEstimCusto.length > 0) {
          maxId += this.ListEnvioInfEstimCusto.reduce(
            (max, item) => (item.id > max ? item.id : max),
            this.ListEnvioInfEstimCusto[0].id,
          )
        }
        this.$refs['EnvioInfEstimCusto-input'].reset() // Limpa input
        const documento = {
          id: maxId,
          solidesenvolvimentoanexoid: 0,
          docnome: this.EnvioInfEstimCusto.name,
          extensao: this.EnvioInfEstimCusto.type,
          docimage: this.EnvioInfEstimCustoBase64,
          origem: $origem,
          tipooperacao: 'INCLUIR',
        }
        if (this.Desenvolvimento.listaSoliDesenvolvimentoAnexo == null) {
          this.Desenvolvimento.listaSoliDesenvolvimentoAnexo = []
        }
        this.Desenvolvimento.listaSoliDesenvolvimentoAnexo.push(documento)
        this.ListEnvioInfEstimCusto.push(documento)
        this.totalRowsEnvioInfEstimCusto = this.ListEnvioInfEstimCusto.length
        this.EnvioInfEstimCusto = null
      }

      if (this.PlanProcEmb != null && $origem === this.PlanProcEmbDescr) {
        let maxId = 1
        if (this.ListPlanProcEmb.length > 0) {
          maxId += this.ListPlanProcEmb.reduce(
            (max, item) => (item.id > max ? item.id : max),
            this.ListPlanProcEmb[0].id,
          )
        }
        this.$refs['PlanProcEmb-input'].reset() // Limpa input
        const documento = {
          id: maxId,
          solidesenvolvimentoanexoid: 0,
          docnome: this.PlanProcEmb.name,
          extensao: this.PlanProcEmb.type,
          docimage: this.PlanProcEmbBase64,
          origem: $origem,
          tipooperacao: 'INCLUIR',
        }
        if (this.Desenvolvimento.listaSoliDesenvolvimentoAnexo == null) {
          this.Desenvolvimento.listaSoliDesenvolvimentoAnexo = []
        }
        this.Desenvolvimento.listaSoliDesenvolvimentoAnexo.push(documento)
        this.ListPlanProcEmb.push(documento)
        this.totalRowsPlanProcEmb = this.ListPlanProcEmb.length
        this.PlanProcEmb = null
      }

      if (this.AprovMpInsumIngr != null && $origem === this.AprovMpInsumIngrDescr) {
        let maxId = 1
        if (this.ListAprovMpInsumIngr.length > 0) {
          maxId += this.ListAprovMpInsumIngr.reduce(
            (max, item) => (item.id > max ? item.id : max),
            this.ListAprovMpInsumIngr[0].id,
          )
        }
        this.$refs['AprovMpInsumIngr-input'].reset() // Limpa input
        const documento = {
          id: maxId,
          solidesenvolvimentoanexoid: 0,
          docnome: this.AprovMpInsumIngr.name,
          extensao: this.AprovMpInsumIngr.type,
          docimage: this.AprovMpInsumIngrBase64,
          origem: $origem,
          tipooperacao: 'INCLUIR',
        }
        if (this.Desenvolvimento.listaSoliDesenvolvimentoAnexo == null) {
          this.Desenvolvimento.listaSoliDesenvolvimentoAnexo = []
        }
        this.Desenvolvimento.listaSoliDesenvolvimentoAnexo.push(documento)
        this.ListAprovMpInsumIngr.push(documento)
        this.totalRowsAprovMpInsumIngr = this.ListAprovMpInsumIngr.length
        this.AprovMpInsumIngr = null
      }

      if (this.EnvHomForn != null && $origem === this.EnvHomFornDescr) {
        let maxId = 1
        if (this.ListEnvHomForn.length > 0) {
          maxId += this.ListEnvHomForn.reduce(
            (max, item) => (item.id > max ? item.id : max),
            this.ListEnvHomForn[0].id,
          )
        }
        this.$refs['EnvHomForn-input'].reset() // Limpa input
        const documento = {
          id: maxId,
          solidesenvolvimentoanexoid: 0,
          docnome: this.EnvHomForn.name,
          extensao: this.EnvHomForn.type,
          docimage: this.EnvHomFornBase64,
          origem: $origem,
          tipooperacao: 'INCLUIR',
        }
        if (this.Desenvolvimento.listaSoliDesenvolvimentoAnexo == null) {
          this.Desenvolvimento.listaSoliDesenvolvimentoAnexo = []
        }
        this.Desenvolvimento.listaSoliDesenvolvimentoAnexo.push(documento)
        this.ListEnvHomForn.push(documento)
        this.totalRowsEnvHomForn = this.ListEnvHomForn.length
        this.EnvHomForn = null
      }

      if (this.EnvInscAnaliHACCP != null && $origem === this.EnvInscAnaliHACCPDescr) {
        let maxId = 1
        if (this.ListEnvInscAnaliHACCP.length > 0) {
          maxId += this.ListEnvInscAnaliHACCP.reduce(
            (max, item) => (item.id > max ? item.id : max),
            this.ListEnvInscAnaliHACCP[0].id,
          )
        }
        this.$refs['EnvInscAnaliHACCP-input'].reset() // Limpa input
        const documento = {
          id: maxId,
          solidesenvolvimentoanexoid: 0,
          docnome: this.EnvInscAnaliHACCP.name,
          extensao: this.EnvInscAnaliHACCP.type,
          docimage: this.EnvInscAnaliHACCPBase64,
          origem: $origem,
          tipooperacao: 'INCLUIR',
        }
        if (this.Desenvolvimento.listaSoliDesenvolvimentoAnexo == null) {
          this.Desenvolvimento.listaSoliDesenvolvimentoAnexo = []
        }
        this.Desenvolvimento.listaSoliDesenvolvimentoAnexo.push(documento)
        this.ListEnvInscAnaliHACCP.push(documento)
        this.totalRowsEnvInscAnaliHACCP = this.ListEnvInscAnaliHACCP.length
        this.EnvInscAnaliHACCP = null
      }

      if (this.TestPiloto != null && $origem === this.TestPilotoDescr) {
        let maxId = 1
        if (this.ListTestPiloto.length > 0) {
          maxId += this.ListTestPiloto.reduce(
            (max, item) => (item.id > max ? item.id : max),
            this.ListTestPiloto[0].id,
          )
        }
        this.$refs['TestPiloto-input'].reset() // Limpa input
        const documento = {
          id: maxId,
          solidesenvolvimentoanexoid: 0,
          docnome: this.TestPiloto.name,
          extensao: this.TestPiloto.type,
          docimage: this.TestPilotoBase64,
          origem: $origem,
          tipooperacao: 'INCLUIR',
        }
        if (this.Desenvolvimento.listaSoliDesenvolvimentoAnexo == null) {
          this.Desenvolvimento.listaSoliDesenvolvimentoAnexo = []
        }
        this.Desenvolvimento.listaSoliDesenvolvimentoAnexo.push(documento)
        this.ListTestPiloto.push(documento)
        this.totalRowsTestPiloto = this.ListTestPiloto.length
        this.TestPiloto = null
      }

      if (this.ValidTermic != null && $origem === this.ValidTermicDescr) {
        let maxId = 1
        if (this.ListValidTermic.length > 0) {
          maxId += this.ListValidTermic.reduce(
            (max, item) => (item.id > max ? item.id : max),
            this.ListValidTermic[0].id,
          )
        }
        this.$refs['ValidTermic-input'].reset() // Limpa input
        const documento = {
          id: maxId,
          solidesenvolvimentoanexoid: 0,
          docnome: this.ValidTermic.name,
          extensao: this.ValidTermic.type,
          docimage: this.ValidTermicBase64,
          origem: $origem,
          tipooperacao: 'INCLUIR',
        }
        if (this.Desenvolvimento.listaSoliDesenvolvimentoAnexo == null) {
          this.Desenvolvimento.listaSoliDesenvolvimentoAnexo = []
        }
        this.Desenvolvimento.listaSoliDesenvolvimentoAnexo.push(documento)
        this.ListValidTermic.push(documento)
        this.totalRowsValidTermic = this.ListValidTermic.length
        this.ValidTermic = null
      }

      if (this.AnalisMicro != null && $origem === this.AnalisMicroDescr) {
        let maxId = 1
        if (this.ListAnalisMicro.length > 0) {
          maxId += this.ListAnalisMicro.reduce(
            (max, item) => (item.id > max ? item.id : max),
            this.ListAnalisMicro[0].id,
          )
        }
        this.$refs['AnalisMicro-input'].reset() // Limpa input
        const documento = {
          id: maxId,
          solidesenvolvimentoanexoid: 0,
          docnome: this.AnalisMicro.name,
          extensao: this.AnalisMicro.type,
          docimage: this.AnalisMicroBase64,
          origem: $origem,
          tipooperacao: 'INCLUIR',
        }
        if (this.Desenvolvimento.listaSoliDesenvolvimentoAnexo == null) {
          this.Desenvolvimento.listaSoliDesenvolvimentoAnexo = []
        }
        this.Desenvolvimento.listaSoliDesenvolvimentoAnexo.push(documento)
        this.ListAnalisMicro.push(documento)
        this.totalRowsAnalisMicro = this.ListAnalisMicro.length
        this.AnalisMicro = null
      }

      if (this.AnalisFisQui != null && $origem === this.AnalisFisQuiDescr) {
        let maxId = 1
        if (this.ListAnalisFisQui.length > 0) {
          maxId += this.ListAnalisFisQui.reduce(
            (max, item) => (item.id > max ? item.id : max),
            this.ListAnalisFisQui[0].id,
          )
        }
        this.$refs['AnalisFisQui-input'].reset() // Limpa input
        const documento = {
          id: maxId,
          solidesenvolvimentoanexoid: 0,
          docnome: this.AnalisFisQui.name,
          extensao: this.AnalisFisQui.type,
          docimage: this.AnalisFisQuiBase64,
          origem: $origem,
          tipooperacao: 'INCLUIR',
        }
        if (this.Desenvolvimento.listaSoliDesenvolvimentoAnexo == null) {
          this.Desenvolvimento.listaSoliDesenvolvimentoAnexo = []
        }
        this.Desenvolvimento.listaSoliDesenvolvimentoAnexo.push(documento)
        this.ListAnalisFisQui.push(documento)
        this.totalRowsAnalisFisQui = this.ListAnalisFisQui.length
        this.AnalisFisQui = null
      }

      if (this.RegistroOrgCompt != null && $origem === this.RegistroOrgComptDescr) {
        let maxId = 1
        if (this.ListRegistroOrgCompt.length > 0) {
          maxId += this.ListRegistroOrgCompt.reduce(
            (max, item) => (item.id > max ? item.id : max),
            this.ListRegistroOrgCompt[0].id,
          )
        }
        this.$refs['RegistroOrgCompt-input'].reset() // Limpa input
        const documento = {
          id: maxId,
          solidesenvolvimentoanexoid: 0,
          docnome: this.RegistroOrgCompt.name,
          extensao: this.RegistroOrgCompt.type,
          docimage: this.RegistroOrgComptBase64,
          origem: $origem,
          tipooperacao: 'INCLUIR',
        }
        if (this.Desenvolvimento.listaSoliDesenvolvimentoAnexo == null) {
          this.Desenvolvimento.listaSoliDesenvolvimentoAnexo = []
        }
        this.Desenvolvimento.listaSoliDesenvolvimentoAnexo.push(documento)
        this.ListRegistroOrgCompt.push(documento)
        this.totalRowsRegistroOrgCompt = this.ListRegistroOrgCompt.length
        this.RegistroOrgCompt = null
      }

      if (this.InfFormPreco != null && $origem === this.InfFormPrecoDescr) {
        let maxId = 1
        if (this.ListInfFormPreco.length > 0) {
          maxId += this.ListInfFormPreco.reduce(
            (max, item) => (item.id > max ? item.id : max),
            this.ListInfFormPreco[0].id,
          )
        }
        this.$refs['InfFormPreco-input'].reset() // Limpa input
        const documento = {
          id: maxId,
          solidesenvolvimentoanexoid: 0,
          docnome: this.InfFormPreco.name,
          extensao: this.InfFormPreco.type,
          docimage: this.InfFormPrecoBase64,
          origem: $origem,
          tipooperacao: 'INCLUIR',
        }
        if (this.Desenvolvimento.listaSoliDesenvolvimentoAnexo == null) {
          this.Desenvolvimento.listaSoliDesenvolvimentoAnexo = []
        }
        this.Desenvolvimento.listaSoliDesenvolvimentoAnexo.push(documento)
        this.ListInfFormPreco.push(documento)
        this.totalRowsInfFormPreco = this.ListInfFormPreco.length
        this.InfFormPreco = null
      }
    },
    async DownloadDocumento(id, $origem) {
      let ListDocumentos = []
      if ($origem === this.PesqMatPriIngDescr) {
        ListDocumentos = this.ListPesqMatPriIng
      }
      if ($origem === this.TestFormDescr) {
        ListDocumentos = this.ListTestForm
      }
      if ($origem === this.EnvioAmostraDescr) {
        ListDocumentos = this.ListEnvioAmostra
      }
      if ($origem === this.AprovAmostClientDescr) {
        ListDocumentos = this.ListAprovAmostClient
      }
      if ($origem === this.EnvioInfEstimCustoDescr) {
        ListDocumentos = this.ListEnvioInfEstimCusto
      }
      if ($origem === this.PlanProcEmbDescr) {
        ListDocumentos = this.ListPlanProcEmb
      }
      if ($origem === this.AprovMpInsumIngrDescr) {
        ListDocumentos = this.ListAprovMpInsumIngr
      }
      if ($origem === this.EnvHomFornDescr) {
        ListDocumentos = this.ListEnvHomForn
      }
      if ($origem === this.EnvInscAnaliHACCPDescr) {
        ListDocumentos = this.ListEnvInscAnaliHACCP
      }
      if ($origem === this.TestPilotoDescr) {
        ListDocumentos = this.ListTestPiloto
      }
      if ($origem === this.ValidTermicDescr) {
        ListDocumentos = this.ListValidTermic
      }
      if ($origem === this.AnalisMicroDescr) {
        ListDocumentos = this.ListAnalisMicro
      }
      if ($origem === this.AnalisFisQuiDescr) {
        ListDocumentos = this.ListAnalisFisQui
      }
      if ($origem === this.RegistroOrgComptDescr) {
        ListDocumentos = this.ListRegistroOrgCompt
      }
      if ($origem === this.InfFormPrecoDescr) {
        ListDocumentos = this.ListInfFormPreco
      }
      ListDocumentos.forEach(Documento => {
        let notification = ''

        // Localiza Documento para Download
        if (Documento.id === id) {
          let DocumentoDownload = Documento
          if (Documento.solidesenvolvimentoanexoid != null) {
            if (Documento.solidesenvolvimentoanexoid !== '' && Documento.solidesenvolvimentoanexoid !== '0') {
              const usuario = this.$session.get('usuario')
              const SOLIDESENVOLVIMENTOID = Documento.solidesenvolvimentoid
              const SOLIDESENVOLVIMENTOANEXOID = Documento.solidesenvolvimentoanexoid
              const URLpost = `/SoliDesenvolvimento/SoliDesenvolvimentoMostraAnexo?CODUSU=${usuario.codusu}&SOLIDESENVOLVIMENTOID=${SOLIDESENVOLVIMENTOID}&SOLIDESENVOLVIMENTOANEXOID=${SOLIDESENVOLVIMENTOANEXOID}`
              apiPortalSky.post(URLpost, SOLIDESENVOLVIMENTOANEXOID).then(response => {
                if (response.status === 200) {
                  DocumentoDownload = response.data
                  if (DocumentoDownload.docimage !== '') {
                    const link = document.createElement('a')
                    link.href = 'data:image;base64,'.concat(link.href.concat(DocumentoDownload.docimage))
                    link.download = DocumentoDownload.docnome
                    link.click()
                  }
                }
              }).catch(error => {
                notification = ConexaoAPI.ValidaErro(error)
                AlertaMsg.FormataMsg(notification)
              })
            }
          } else if (DocumentoDownload.docimage !== '') {
            const link = document.createElement('a')
            link.href = 'data:image;base64,'.concat(link.href.concat(DocumentoDownload.docimage))
            link.download = DocumentoDownload.docnome
            link.click()
          }
        }
      })
    },
    AtualizaTotalRows() {
      this.totalRowsPesqMatPriIng = this.ListPesqMatPriIng.length
      this.totalRowsTestForm = this.ListTestForm.length
      this.totalRowsEnvioAmostra = this.ListEnvioAmostra.length
      this.totalRowsAprovAmostClient = this.ListAprovAmostClient.length
      this.totalRowsEnvioInfEstimCusto = this.ListEnvioInfEstimCusto.length
      this.totalRowsPlanProcEmb = this.ListPlanProcEmb.length
      this.totalRowsAprovMpInsumIngr = this.ListAprovMpInsumIngr.length
      this.totalRowsEnvHomForn = this.ListEnvHomForn.length
      this.totalRowsEnvInscAnaliHACCP = this.ListEnvInscAnaliHACCP.length
      this.totalRowsTestPiloto = this.ListTestPiloto.length
      this.totalRowsValidTermic = this.ListValidTermic.length
      this.totalRowsAnalisMicro = this.ListAnalisMicro.length
      this.totalRowsAnalisFisQui = this.ListAnalisFisQui.length
      this.totalRowsRegistroOrgCompt = this.ListRegistroOrgCompt.length
      this.totalRowsInfFormPreco = this.ListInfFormPreco.length
    },
    CarregaDadosNoObjeto() {
      this.Desenvolvimento.solidesenvolvimentoid = this.solidesenvolvimentoid
      this.Desenvolvimento.numsoliproduto = this.numsoliproduto
      this.Desenvolvimento.datainicdesenvolvimento = this.datainicdesenvolvimento
      this.Desenvolvimento.plandesdatap = this.plandesdatap
      this.Desenvolvimento.plandesdatar = this.plandesdatar
      this.Desenvolvimento.plandesdesc = this.plandesdesc
      this.Desenvolvimento.pesqmatpriingdatap = this.pesqmatpriingdatap
      this.Desenvolvimento.pesqmatpriingdatar = this.pesqmatpriingdatar
      this.Desenvolvimento.pesqmatpriingdesc = this.pesqmatpriingdesc
      this.Desenvolvimento.testformdatap = this.testformdatap
      this.Desenvolvimento.testformdatar = this.testformdatar
      this.Desenvolvimento.testformdesc = this.testformdesc
      this.Desenvolvimento.envioamostradatap = this.envioamostradatap
      this.Desenvolvimento.envioamostradatar = this.envioamostradatar
      this.Desenvolvimento.envioamostradesc = this.envioamostradesc
      this.Desenvolvimento.aprovamostclient = this.aprovamostclient
      this.Desenvolvimento.aprovamostclientdesc = this.aprovamostclientdesc
      this.Desenvolvimento.envioinfestimcustodatap = this.envioinfestimcustodatap
      this.Desenvolvimento.envioinfestimcustodatar = this.envioinfestimcustodatar
      this.Desenvolvimento.envioinfestimcustodesc = this.envioinfestimcustodesc
      this.Desenvolvimento.estimcustorskgdatap = this.estimcustorskgdatap
      this.Desenvolvimento.estimcustorskgdatar = this.estimcustorskgdatar
      this.Desenvolvimento.estimcustorskgdesc = this.estimcustorskgdesc
      this.Desenvolvimento.planprocembdatap = this.planprocembdatap
      this.Desenvolvimento.planprocembdatar = this.planprocembdatar
      this.Desenvolvimento.planprocembdesc = this.planprocembdesc
      this.Desenvolvimento.aprovmpinsumingrdatap = this.aprovmpinsumingrdatap
      this.Desenvolvimento.aprovmpinsumingrdatar = this.aprovmpinsumingrdatar
      this.Desenvolvimento.aprovmpinsumingrdesc = this.aprovmpinsumingrdesc
      this.Desenvolvimento.envhomforndatap = this.envhomforndatap
      this.Desenvolvimento.envhomforndatar = this.envhomforndatar
      this.Desenvolvimento.envhomforndesc = this.envhomforndesc
      this.Desenvolvimento.envinscanalihaccpdatap = this.envinscanalihaccpdatap
      this.Desenvolvimento.envinscanalihaccpdatar = this.envinscanalihaccpdatar
      this.Desenvolvimento.envinscanalihaccpdesc = this.envinscanalihaccpdesc
      this.Desenvolvimento.analiseperigproddatap = this.analiseperigproddatap
      this.Desenvolvimento.analiseperigproddatar = this.analiseperigproddatar
      this.Desenvolvimento.analiseperigproddesc = this.analiseperigproddesc
      this.Desenvolvimento.testpilotodatap = this.testpilotodatap
      this.Desenvolvimento.testpilotodatar = this.testpilotodatar
      this.Desenvolvimento.testpilotodesc = this.testpilotodesc
      this.Desenvolvimento.validtermicdatap = this.validtermicdatap
      this.Desenvolvimento.validtermicdatar = this.validtermicdatar
      this.Desenvolvimento.validtermicdesc = this.validtermicdesc
      this.Desenvolvimento.progautodef = this.progautodef
      this.Desenvolvimento.f0alcan = this.f0alcan
      this.Desenvolvimento.analismicrodatap = this.analismicrodatap
      this.Desenvolvimento.analismicrodatar = this.analismicrodatar
      this.Desenvolvimento.analismicrodesc = this.analismicrodesc
      this.Desenvolvimento.analisfisquidatap = this.analisfisquidatap
      this.Desenvolvimento.analisfisquidatar = this.analisfisquidatar
      this.Desenvolvimento.analisfisquidesc = this.analisfisquidesc
      this.Desenvolvimento.incshelflifedatap = this.incshelflifedatap
      this.Desenvolvimento.incshelflifedatar = this.incshelflifedatar
      this.Desenvolvimento.incshelflifedesc = this.incshelflifedesc
      this.Desenvolvimento.calcnutricionaldatap = this.calcnutricionaldatap
      this.Desenvolvimento.calcnutricionaldatar = this.calcnutricionaldatar
      this.Desenvolvimento.calcnutricionaldesc = this.calcnutricionaldesc
      this.Desenvolvimento.it = this.it
      this.Desenvolvimento.etmpinsumo = this.etmpinsumo
      this.Desenvolvimento.etpa = this.etpa
      this.Desenvolvimento.croqrotulagem = this.croqrotulagem
      this.Desenvolvimento.cadproduto = this.cadproduto
      this.Desenvolvimento.registroorgcomptdatap = this.registroorgcomptdatap
      this.Desenvolvimento.registroorgcomptdatar = this.registroorgcomptdatar
      this.Desenvolvimento.registroorgcomptdesc = this.registroorgcomptdesc
      this.Desenvolvimento.anvisa = this.anvisa !== 'Sim' ? 'Não' : 'Sim'
      this.Desenvolvimento.mapa = this.mapa !== 'Sim' ? 'Não' : 'Sim'
      this.Desenvolvimento.organico = this.organico !== 'Sim' ? 'Não' : 'Sim'
      this.Desenvolvimento.docdispsgi = this.docdispsgi
      this.Desenvolvimento.rotuldispsgi = this.rotuldispsgi
      this.Desenvolvimento.infformprecodatap = this.infformprecodatap
      this.Desenvolvimento.infformprecodatar = this.infformprecodatar
      this.Desenvolvimento.infformprecodesc = this.infformprecodesc
      this.Desenvolvimento.treinprodliddatap = this.treinprodliddatap
      this.Desenvolvimento.treinprodliddatar = this.treinprodliddatar
      this.Desenvolvimento.treinprodliddesc = this.treinprodliddesc
      this.Desenvolvimento.primeiraproddatap = this.primeiraproddatap
      this.Desenvolvimento.primeiraproddatar = this.primeiraproddatar
      this.Desenvolvimento.primeiraproddesc = this.primeiraproddesc
      this.Desenvolvimento.segundaproddatap = this.segundaproddatap
      this.Desenvolvimento.segundaproddatar = this.segundaproddatar
      this.Desenvolvimento.segundaproddesc = this.segundaproddesc
      this.Desenvolvimento.terceiraproddatap = this.terceiraproddatap
      this.Desenvolvimento.terceiraproddatar = this.terceiraproddatar
      this.Desenvolvimento.terceiraproddesc = this.terceiraproddesc
      this.Desenvolvimento.licoesaprenddata = this.licoesaprenddata
      this.Desenvolvimento.licoesaprenddesc = this.licoesaprenddesc
      this.Desenvolvimento.statusworkflowid = this.statusworkflowid
      // Campos de Operação
      this.Desenvolvimento.tipoalteracao = this.tipoalteracao
      this.Desenvolvimento.acessoetapa = this.acessoetapa
    },
    DescarregaDadosDoObjeto() {
      this.solidesenvolvimentoid = this.Desenvolvimento.solidesenvolvimentoid
      this.numsoliproduto = this.Desenvolvimento.numsoliproduto
      this.produto = this.Desenvolvimento.produto
      this.datainicdesenvolvimento = this.Desenvolvimento.datainicdesenvolvimento
      this.plandesdatap = this.Desenvolvimento.plandesdatap
      this.plandesdatar = this.Desenvolvimento.plandesdatar
      this.plandesdesc = this.Desenvolvimento.plandesdesc
      this.pesqmatpriingdatap = this.Desenvolvimento.pesqmatpriingdatap
      this.pesqmatpriingdatar = this.Desenvolvimento.pesqmatpriingdatar
      this.pesqmatpriingdesc = this.Desenvolvimento.pesqmatpriingdesc
      this.testformdatap = this.Desenvolvimento.testformdatap
      this.testformdatar = this.Desenvolvimento.testformdatar
      this.testformdesc = this.Desenvolvimento.testformdesc
      this.envioamostradatap = this.Desenvolvimento.envioamostradatap
      this.envioamostradatar = this.Desenvolvimento.envioamostradatar
      this.envioamostradesc = this.Desenvolvimento.envioamostradesc
      this.aprovamostclient = this.Desenvolvimento.aprovamostclient
      this.aprovamostclientdesc = this.Desenvolvimento.aprovamostclientdesc
      this.envioinfestimcustodatap = this.Desenvolvimento.envioinfestimcustodatap
      this.envioinfestimcustodatar = this.Desenvolvimento.envioinfestimcustodatar
      this.envioinfestimcustodesc = this.Desenvolvimento.envioinfestimcustodesc
      this.estimcustorskgdatap = this.Desenvolvimento.estimcustorskgdatap
      this.estimcustorskgdatar = this.Desenvolvimento.estimcustorskgdatar
      this.estimcustorskgdesc = this.Desenvolvimento.estimcustorskgdesc
      this.planprocembdatap = this.Desenvolvimento.planprocembdatap
      this.planprocembdatar = this.Desenvolvimento.planprocembdatar
      this.planprocembdesc = this.Desenvolvimento.planprocembdesc
      this.aprovmpinsumingrdatap = this.Desenvolvimento.aprovmpinsumingrdatap
      this.aprovmpinsumingrdatar = this.Desenvolvimento.aprovmpinsumingrdatar
      this.aprovmpinsumingrdesc = this.Desenvolvimento.aprovmpinsumingrdesc
      this.envhomforndatap = this.Desenvolvimento.envhomforndatap
      this.envhomforndatar = this.Desenvolvimento.envhomforndatar
      this.envhomforndesc = this.Desenvolvimento.envhomforndesc
      this.envinscanalihaccpdatap = this.Desenvolvimento.envinscanalihaccpdatap
      this.envinscanalihaccpdatar = this.Desenvolvimento.envinscanalihaccpdatar
      this.envinscanalihaccpdesc = this.Desenvolvimento.envinscanalihaccpdesc
      this.analiseperigproddatap = this.Desenvolvimento.analiseperigproddatap
      this.analiseperigproddatar = this.Desenvolvimento.analiseperigproddatar
      this.analiseperigproddesc = this.Desenvolvimento.analiseperigproddesc
      this.testpilotodatap = this.Desenvolvimento.testpilotodatap
      this.testpilotodatar = this.Desenvolvimento.testpilotodatar
      this.testpilotodesc = this.Desenvolvimento.testpilotodesc
      this.validtermicdatap = this.Desenvolvimento.validtermicdatap
      this.validtermicdatar = this.Desenvolvimento.validtermicdatar
      this.validtermicdesc = this.Desenvolvimento.validtermicdesc
      this.progautodef = this.Desenvolvimento.progautodef
      this.f0alcan = this.Desenvolvimento.f0alcan
      this.analismicrodatap = this.Desenvolvimento.analismicrodatap
      this.analismicrodatar = this.Desenvolvimento.analismicrodatar
      this.analismicrodesc = this.Desenvolvimento.analismicrodesc
      this.analisfisquidatap = this.Desenvolvimento.analisfisquidatap
      this.analisfisquidatar = this.Desenvolvimento.analisfisquidatar
      this.analisfisquidesc = this.Desenvolvimento.analisfisquidesc
      this.incshelflifedatap = this.Desenvolvimento.incshelflifedatap
      this.incshelflifedatar = this.Desenvolvimento.incshelflifedatar
      this.incshelflifedesc = this.Desenvolvimento.incshelflifedesc
      this.calcnutricionaldatap = this.Desenvolvimento.calcnutricionaldatap
      this.calcnutricionaldatar = this.Desenvolvimento.calcnutricionaldatar
      this.calcnutricionaldesc = this.Desenvolvimento.calcnutricionaldesc
      this.it = this.Desenvolvimento.it
      this.etmpinsumo = this.Desenvolvimento.etmpinsumo
      this.etpa = this.Desenvolvimento.etpa
      this.croqrotulagem = this.Desenvolvimento.croqrotulagem
      this.cadproduto = this.Desenvolvimento.cadproduto
      this.registroorgcomptdatap = this.Desenvolvimento.registroorgcomptdatap
      this.registroorgcomptdatar = this.Desenvolvimento.registroorgcomptdatar
      this.registroorgcomptdesc = this.Desenvolvimento.registroorgcomptdesc
      this.anvisa = this.Desenvolvimento.anvisa
      this.mapa = this.Desenvolvimento.mapa
      this.organico = this.Desenvolvimento.organico
      this.docdispsgi = this.Desenvolvimento.docdispsgi
      this.rotuldispsgi = this.Desenvolvimento.rotuldispsgi
      this.infformprecodatap = this.Desenvolvimento.infformprecodatap
      this.infformprecodatar = this.Desenvolvimento.infformprecodatar
      this.infformprecodesc = this.Desenvolvimento.infformprecodesc
      this.treinprodliddatap = this.Desenvolvimento.treinprodliddatap
      this.treinprodliddatar = this.Desenvolvimento.treinprodliddatar
      this.treinprodliddesc = this.Desenvolvimento.treinprodliddesc
      this.primeiraproddatap = this.Desenvolvimento.primeiraproddatap
      this.primeiraproddatar = this.Desenvolvimento.primeiraproddatar
      this.primeiraproddesc = this.Desenvolvimento.primeiraproddesc
      this.segundaproddatap = this.Desenvolvimento.segundaproddatap
      this.segundaproddatar = this.Desenvolvimento.segundaproddatar
      this.segundaproddesc = this.Desenvolvimento.segundaproddesc
      this.terceiraproddatap = this.Desenvolvimento.terceiraproddatap
      this.terceiraproddatar = this.Desenvolvimento.terceiraproddatar
      this.terceiraproddesc = this.Desenvolvimento.terceiraproddesc
      this.licoesaprenddata = this.Desenvolvimento.licoesaprenddata
      this.licoesaprenddesc = this.Desenvolvimento.licoesaprenddesc
      this.statusworkflowid = this.Desenvolvimento.statusworkflowid
      // Campos de Operacao
      this.tipoalteracao = this.Desenvolvimento.tipoalteracao
      this.acessoetapa = this.Desenvolvimento.acessoetapa

      // Anexos
      this.Desenvolvimento.listaSoliDesenvolvimentoAnexo.forEach(Documento => {
        // Adciona Anexo nas Devidas Listas
        if (Documento.origem === this.PesqMatPriIngDescr) {
          this.ListPesqMatPriIng.push(Documento)
        }
        if (Documento.origem === this.TestFormDescr) {
          this.ListTestForm.push(Documento)
        }
        if (Documento.origem === this.EnvioAmostraDescr) {
          this.ListEnvioAmostra.push(Documento)
        }
        if (Documento.origem === this.AprovAmostClientDescr) {
          this.ListAprovAmostClient.push(Documento)
        }
        if (Documento.origem === this.EnvioInfEstimCustoDescr) {
          this.ListEnvioInfEstimCusto.push(Documento)
        }
        if (Documento.origem === this.PlanProcEmbDescr) {
          this.ListPlanProcEmb.push(Documento)
        }
        if (Documento.origem === this.AprovMpInsumIngrDescr) {
          this.ListAprovMpInsumIngr.push(Documento)
        }
        if (Documento.origem === this.EnvHomFornDescr) {
          this.ListEnvHomForn.push(Documento)
        }
        if (Documento.origem === this.EnvInscAnaliHACCPDescr) {
          this.ListEnvInscAnaliHACCP.push(Documento)
        }
        if (Documento.origem === this.TestPilotoDescr) {
          this.ListTestPiloto.push(Documento)
        }
        if (Documento.origem === this.ValidTermicDescr) {
          this.ListValidTermic.push(Documento)
        }
        if (Documento.origem === this.AnalisMicroDescr) {
          this.ListAnalisMicro.push(Documento)
        }
        if (Documento.origem === this.AnalisFisQuiDescr) {
          this.ListAnalisFisQui.push(Documento)
        }
        if (Documento.origem === this.RegistroOrgComptDescr) {
          this.ListRegistroOrgCompt.push(Documento)
        }
        if (Documento.origem === this.InfFormPrecoDescr) {
          this.ListInfFormPreco.push(Documento)
        }
      })
    },
  },
  mounted() {
    if (this.$route.params.solidesenvolvimentoid != null) {
      this.ConsultaStatusWorkFlow()
      this.MostraFormularioSolicitacao(this.$route.params.solidesenvolvimentoid)
    }
  },
}
</script>
